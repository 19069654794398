import React, { ReactElement } from 'react';
import { useModal } from '../../../hooks/modal/useModal';
import AgentsInfoModal from '../../settings-page/modal/agents/AgentsInfoModal';
import CreateAgentModal from '../../settings-page/modal/agents/CreateAgentModal';
import DeleteAgentModal from '../../settings-page/modal/agents/DeleteAgentModal';
import EditAgentModal from '../../settings-page/modal/agents/EditAgentModal';
import style from '../../../assets/scss/components/GeneralModal.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { Modal } from 'react-bootstrap';
import { ModalType } from '../../../interfaces/redux/TUIState';
import { useModalListeners } from '../../../hooks/modal/useModalListeners';
import CreateInboxModal from '../../settings-page/modal/inboxes/CreateInboxModal';
import EditInboxModal from '../../settings-page/modal/inboxes/EditInboxModal';
import InboxesInfoModal from '../../settings-page/modal/inboxes/InboxesInfoModal';
import DeleteInboxModal from '../../settings-page/modal/inboxes/DeleteInboxModal';
import { CreateBlockModal } from '../../settings-page/modal/block/CreateBlockModal';
import { EditTextBlockModal } from '../../settings-page/modal/block/EditTextBlockModal';
import CreateCannedResponseModal from '../../settings-page/modal/canned-responses/CreateCannedResponseModal';
import CannedResponsesInfoModal from '../../settings-page/modal/canned-responses/CannedResponsesInfoModal';
import EditCannedResponseModal from '../../settings-page/modal/canned-responses/EditCannedResponseModal';
import DeleteCannedResponseModal from '../../settings-page/modal/canned-responses/DeleteCannedResponseModal';
import WebclientConfigInfoModal from '../../settings-page/modal/webclient/WebclientConfigInfoModal';
import WebclientWhitelistInfoModal from '../../settings-page/modal/webclient/whitelist/WebclientWhitelistInfoModal';
import WebclientSnippetModal from '../../settings-page/modal/webclient/WebclientSnippetModal';
import CreateWebclientConfigModal from '../../settings-page/modal/webclient/CreateWebclientConfigModal';
import EditWebclientConfigModal from '../../settings-page/modal/webclient/EditWebclientConfigModal';
import DeleteWebclientConfigModal from '../../settings-page/modal/webclient/DeleteWebclientConfigModal';
import WebclientInfoModal from '../../settings-page/modal/webclient/WebclientInfoModal';
import { LabelInfoModal } from '../../settings-page/modal/labels/LabelInfoModal';
import { CreateLabelModal } from '../../settings-page/modal/labels/CreateLabelModal';
import { EditLabelModal } from '../../settings-page/modal/labels/EditLabelModal';
import { DeleteLabelModal } from '../../settings-page/modal/labels/DeleteLabelModal';
import AddWebclientWhitelistModal from '../../settings-page/modal/webclient/whitelist/AddWebclientWhitelistModal';
import RemoveWebclientWhitelistModal from '../../settings-page/modal/webclient/whitelist/RemoveWebclientWhitelistModal';

type TModal = {
  component: () => ReactElement;
  title: string;
};

const modalMap: Record<ModalType, TModal> = {
  'add-agent': { component: () => <CreateAgentModal />, title: 'Add agent' },
  'agent-info': { component: () => <AgentsInfoModal />, title: 'Agent info' },
  'edit-agent': { component: () => <EditAgentModal />, title: 'Edit agent' },
  'delete-agent': { component: () => <DeleteAgentModal />, title: 'Delete agent' },
  'add-inbox': { component: () => <CreateInboxModal />, title: 'Add inbox' },
  'inbox-info': { component: () => <InboxesInfoModal />, title: 'Inbox info' },
  'edit-inbox': { component: () => <EditInboxModal />, title: 'Edit inbox' },
  'delete-inbox': { component: () => <DeleteInboxModal />, title: 'Delete inbox' },
  'add-block': { component: () => <CreateBlockModal />, title: 'Add block' },
  'edit-block': { component: () => <EditTextBlockModal />, title: 'Edit block' },
  'add-canned-response': { component: () => <CreateCannedResponseModal />, title: 'Add canned response' },
  'canned-response-info': { component: () => <CannedResponsesInfoModal />, title: 'Canned responses info' },
  'edit-canned-response': { component: () => <EditCannedResponseModal />, title: 'Edit canned response' },
  'delete-canned-response': { component: () => <DeleteCannedResponseModal />, title: 'Delete canned responses' },
  'edit-webclient-config': { component: () => <EditWebclientConfigModal />, title: 'Edit webclient config' },
  'webclient-config-info': { component: () => <WebclientConfigInfoModal />, title: 'Webclient config info' },
  'webclient-whitelist-info': { component: () => <WebclientWhitelistInfoModal />, title: 'Webclient whitelist info' },
  'webclient-snippet': { component: () => <WebclientSnippetModal />, title: 'Webclient connector snippet' },
  'create-webclient-config': { component: () => <CreateWebclientConfigModal />, title: 'Create webclient config' },
  'delete-webclient-config': { component: () => <DeleteWebclientConfigModal />, title: 'Delete webclient config' },
  'webclient-info': { component: () => <WebclientInfoModal />, title: 'Webclient info' },
  'info-label': { component: () => <LabelInfoModal />, title: 'Label info' },
  'add-label': { component: () => <CreateLabelModal />, title: 'Add label' },
  'delete-label': { component: () => <DeleteLabelModal />, title: 'Delete label' },
  'edit-label': { component: () => <EditLabelModal />, title: 'Edit label' },
  'add-webclient-whitelist': { component: () => <AddWebclientWhitelistModal />, title: 'Add whitelisted domain' },
  'delete-webclient-whitelist': {
    component: () => <RemoveWebclientWhitelistModal />,
    title: 'Remove whitelisted domain',
  },
};

export const ModalContainer: React.FC = () => {
  const { type, hideModal } = useModal();
  useModalListeners();
  const { modalInbox, modalHeader } = convertKeysToCamelCase(style);

  const activeModal = type ? modalMap[type] : null;

  return (
    <Modal show={!!activeModal} aria-labelledby="contained-modal-title-vcenter" centered className={`${modalInbox}`}>
      <Modal.Header className={`${modalHeader}`} onClick={() => hideModal()} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{activeModal?.title}</h3>
        </Modal.Title>
      </Modal.Header>
      {activeModal?.component()}
    </Modal>
  );
};
