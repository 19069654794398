import React from 'react';
import { Button, Modal, Form, Row } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/ModalForm.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { TCannedResponse, TCannedResponseUpdateable } from '../../../../interfaces/TChat';

type Props = {
  cannedResponseData?: Partial<TCannedResponse>;
  onSubmit: (data: TCannedResponseUpdateable) => void;
};

type TCannedResponseFormData = TCannedResponseUpdateable;

export const CannedResponseDataModal: React.FC<Props> = ({ cannedResponseData, onSubmit }) => {
  const { inputForm, inputGroup, input, modalSection, modalInput, footer } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm<TCannedResponseFormData>({
    shouldUseNativeValidation: true,
    defaultValues: {
      message: cannedResponseData?.message,
      shorthand: cannedResponseData?.shorthand,
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: TCannedResponseFormData) => {
    onSubmit({
      message: data.message,
      shorthand: data.shorthand,
    });
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <div>
          <div className={`${modalSection} ${modalInput}`}>
            <Form onSubmit={handleSubmit<TCannedResponseFormData>(hideAndSubmit)}>
              <Row className={`${inputGroup}`}>
                <Form.Group className={`${inputForm}`}>
                  <p>Shorthand</p>
                  <Form.Control
                    className={input}
                    {...register('shorthand', { required: 'Please enter the shorthand' })}
                  />
                </Form.Group>
                <Form.Group className={`${inputForm}`}>
                  <p>Response</p>
                  <Form.Control className={input} {...register('message', { required: 'Please enter the message' })} />
                </Form.Group>
              </Row>
              <div className={`${footer}`}>
                <Modal.Footer>
                  <Button type="submit">Save</Button>
                </Modal.Footer>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default CannedResponseDataModal;
