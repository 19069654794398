import React from 'react';
import chatAvatar from '../../../../assets/images/chat_avatar.png';
import style from '../../../../assets/scss/components/header/dropdown/ProfileDropdown.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import { Link } from 'react-router-dom';
import { useMainSelector } from '../../../../hooks/useMainSelector';
import { useCompanySelector } from '../../../../hooks/useCompanySelector';

export const ProfileDropdown: React.FC = () => {
  const {
    user: { currentUser },
  } = useMainSelector();

  const { userNameType, cvUser, userAvatar, cvProfileDropdown, wrapperTop, wrapperMiddle, wrapperBottom, cvStatus } =
    convertKeysToCamelCase(style);

  const { selectedCompany, openCompanySelector } = useCompanySelector();

  return (
    <ul className={cvProfileDropdown}>
      <div className={wrapperTop}>
        <div className={cvUser}>
          <img src={chatAvatar} className={userAvatar} />
          <div className={userNameType}>
            {currentUser?.firstName}
            <span>Administrator</span>
          </div>
        </div>
        <span className={cvStatus}>Online</span>
      </div>
      <div className={wrapperMiddle}>
        <div>
          <span className="material-icons">settings</span>
          <span>Account settings</span>
        </div>
        <div role="button" onClick={openCompanySelector}>
          <span className="material-icons">switch_account</span>
          <span>Choose customer ({selectedCompany?.name})</span>
          <span className="material-icons">arrow_forward_ios</span>
        </div>
        <div>
          <span className="material-icons">translate</span>
          <span>Language: English</span>
          <span className="material-icons">arrow_forward_ios</span>
        </div>
      </div>
      <div className={wrapperBottom}>
        <Link to="/logout">
          <span className="material-icons">logout</span>
          <span>Log Out</span>
        </Link>
      </div>
    </ul>
  );
};

export default ProfileDropdown;
