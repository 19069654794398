import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { MAIN_PAGE_ROUTE } from '../../constants/routes';
import { IRestrictedRoute } from '../../interfaces/IRoutes';
import { checkPermissions } from '../../helpers/permissionsCheckHelpers';
import { useMainSelector } from '../../hooks/useMainSelector';

export const RestrictedRoute: React.FC<IRestrictedRoute> = ({ component: Component, requiredPermissions, ...rest }) => {
  const {
    user: { permissions },
  } = useMainSelector();

  const canRoute = checkPermissions(requiredPermissions, permissions);

  return (
    <Route
      {...rest}
      render={(childProps) => (canRoute ? <Component {...childProps} /> : <Redirect to={MAIN_PAGE_ROUTE} />)}
    />
  );
};
