import { Actions } from '../constants/actionTypes';
import { ESocketEvents } from '../constants/ESocketEvents';
import useSocket from './useSocket';
import { useCallback } from 'react';
import { useMainSelector } from './useMainSelector';
import { useMainDispatch } from './useMainDispatch';

export const useChat = () => {
  const { send } = useSocket();

  const {
    chat: { currentUser },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const sendMessage = useCallback(
    (message?: string) => {
      if (currentUser) {
        send(ESocketEvents.NEW_AGENT_MESSAGE, {
          userData: {
            chatBotUserId: currentUser.id,
            chatBotPlatform: currentUser.platform,
          },
          data: message,
        });
      }
    },
    [currentUser, send],
  );

  const sendNote = useCallback(
    (message?: string) => {
      if (currentUser) {
        send(ESocketEvents.NEW_AGENT_NOTE, {
          userData: {
            chatBotUserId: currentUser.id,
            chatBotPlatform: currentUser.platform,
          },
          data: message,
        });
      }
    },
    [currentUser, send],
  );

  const sendAgentTyping = useCallback(
    (isTyping?: boolean) => {
      if (currentUser) {
        send(ESocketEvents.AGENT_TYPING, {
          userData: {
            chatBotUserId: currentUser.id,
            chatBotPlatform: currentUser.platform,
          },
          data: {
            action: isTyping ? 'typing_on' : 'typing_off',
          },
        });
      }
    },
    [currentUser, send],
  );

  const resolveChat = useCallback(() => {
    dispatch({ type: Actions.SET_CHAT, payload: { currentUser: undefined, currentUserMessages: [] } });
    send(ESocketEvents.HANDOVER_TO_BOT, {
      userData: {
        chatBotPlatform: currentUser?.platform,
        chatBotUserId: currentUser?.id,
      },
    });
  }, [dispatch, currentUser, send]);

  const closeChat = useCallback(() => {
    dispatch({ type: Actions.SET_CHAT, payload: { currentUser: undefined, currentUserMessages: [] } });
    dispatch({
      type: Actions.SET_UI,
      payload: {
        chatPageView: 'list',
      },
    });
  }, [dispatch]);

  return {
    sendMessage,
    sendNote,
    sendAgentTyping,
    resolveChat,
    closeChat,
  };
};

export default useChat;
