import { getHandlePositionByFlowDirection } from '../../../helpers/flowHelper';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/nodes/FlowNode.module.scss';
import { TBlockWithPosition } from '../../../interfaces/TDesigner';
import { Handle } from 'react-flow-renderer';
import { useFlow } from '../../../hooks/flow/flow/useFlow';

interface IProps {
  data: TBlockWithPosition;
}

export const FlowNode: React.FC<IProps> = ({ data }) => {
  const { id } = data;
  const { flowDirection } = useFlow();
  const { start, end } = getHandlePositionByFlowDirection(flowDirection);
  const { node, nodeSeparator, nodeBody, nodeTitle } = convertKeysToCamelCase(style);

  return (
    <div className={node}>
      <Handle type="target" position={start} id="start" />
      <div className={nodeTitle}>
        <p>
          <strong>{id}</strong>
        </p>
      </div>
      <hr className={nodeSeparator} />
      <div className={nodeBody}></div>
      <Handle type="source" position={end} id="end" />
    </div>
  );
};
