import style from '../../../assets/scss/components/sidebar/AppSidebar.module.scss';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import { useSidebar } from '../../../hooks/useSidebar';
import { useMainSelector } from '../../../hooks/useMainSelector';
import { checkPermissions } from '../../../helpers/permissionsCheckHelpers';
import { SidebarRoutes } from '../../routes/SidebarRoutes';
import { SidebarItem } from './SidebarItem';
import { SidebarFooter } from './SidebarFooter';

const setUpSidebarRoutes = (availablePermissions?: string[]) => {
  // * If there are no permissions do not render anything
  if (!availablePermissions) {
    return null;
  }

  // * Render only items the user has permissions for
  return SidebarRoutes.filter(({ permissions }) => checkPermissions(permissions, availablePermissions)).map(
    ({ icon, text, to }) => <SidebarItem key={text} icon={icon} text={text} to={to} />,
  );
};

export const AppSidebar = () => {
  const { sideBarOpen } = useSidebar();

  const {
    user: { permissions },
  } = useMainSelector();
  const { sidebarMenu, active } = convertKeysToCamelCase(style);

  return (
    <div id={sidebarMenu} className={getCSS(!sideBarOpen, active)}>
      <div>{setUpSidebarRoutes(permissions)}</div>
      <SidebarFooter />
    </div>
  );
};
