import style from '../../../assets/scss/components/sidebar/AppSidebar.module.scss';
import { Link } from 'react-router-dom';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { ISideBarItem } from '../../../interfaces/ISidebar';
import { MaterialIcon } from '../../common/MaterialIcon';

export const SidebarItem: React.FC<ISideBarItem> = ({ to, text, icon }) => {
  const { sidebarItem, wrapper } = convertKeysToCamelCase(style);

  return (
    <Link to={to}>
      <div className={sidebarItem}>
        <div className={wrapper}>
          <MaterialIcon icon={icon} classes={['icon']} />
          <h5>{text}</h5>
        </div>
      </div>
    </Link>
  );
};

export default SidebarItem;
