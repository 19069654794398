import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { AgentResponseDTO, CreateAgentRequestDTO } from './dto/agent.dto';
import { TGeneralResponse } from './dto/common.dto';

export const getAgentsEndpoint = (): TGeneralResponse<AgentResponseDTO[]> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.AGENTS);

export const createAgentsEndpoint = (agentData: CreateAgentRequestDTO): TGeneralResponse<AgentResponseDTO> =>
  axiosInterceptAccessTokenExpiry().post(ENDPOINTS.AGENTS, agentData);

export const updateAgentsEndpoint = (agentId: string, agentData: CreateAgentRequestDTO): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.AGENTS}/${agentId}`, agentData);

export const deleteAgentsEndpoint = (agentId: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.AGENTS}/${agentId}`);
