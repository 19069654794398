import { useCallback } from 'react';
import { commitBlockChangesApi } from '../../api/botApi';
import { Actions } from '../../constants/actionTypes';
import { toastErrorsTranslated, toastSuccessTranslated } from '../../helpers/toastErrors';
import { EFlowDirection } from '../../interfaces/EFlowDirection';
import { useFileDownload } from '../useFileDownload';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';
import { useFlowNode } from './useFlowNode';

export const useFlowControls = () => {
  const {
    designer: { flowDirection },
  } = useMainSelector();
  const dispatch = useMainDispatch();
  const { downloadJSON } = useFileDownload();
  const { getBlocksFromNodesWithoutPosition } = useFlowNode();

  const commitBlockChanges = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      await commitBlockChangesApi();
      toastSuccessTranslated('flow.commit.success');
    } catch (error) {
      console.error(error);
      toastErrorsTranslated(error);
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const flipFlowLayout = useCallback(() => {
    dispatch({
      type: Actions.SET_DESIGNER,
      payload: {
        flowDirection: flowDirection === EFlowDirection.Vertical ? EFlowDirection.Horizontal : EFlowDirection.Vertical,
      },
    });
  }, [dispatch, flowDirection]);

  const downloadBlocks = useCallback(async () => {
    try {
      const blocks = getBlocksFromNodesWithoutPosition();

      downloadJSON({
        data: JSON.stringify(blocks),
        fileName: 'blocks.json',
      });
    } catch (error) {
      console.error(error);
    }
  }, [downloadJSON, getBlocksFromNodesWithoutPosition]);

  return {
    commitBlockChanges,
    downloadBlocks,
    flipFlowLayout,
    flowDirection,
  };
};
