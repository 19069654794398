import { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  getMessagesOfConversationApi,
  assignCurrentAgentToConversation,
  getConversationByIdApi,
  getConversationByInboxApi,
} from '../api/chatApi';
import { getInboxesOfAgentApi } from '../api/inboxApi';
import { Actions } from '../constants/actionTypes';
import { CHAT_PAGE_ROUTE } from '../constants/routes';
import { toastErrorsTranslated } from '../helpers/toastErrors';
import { useCannedResponsesActions } from './chat/useCannedResponsesActions';
import { useMainDispatch } from './useMainDispatch';
import { useMainSelector } from './useMainSelector';

export const useChatEvents = () => {
  const {
    chat: { currentUser, selectedInbox },
  } = useMainSelector();
  const dispatch = useMainDispatch();
  const history = useHistory();

  const { removeCannedResponses } = useCannedResponsesActions();

  const { id: paramsChatBotUserID } = useParams<any>();

  const getChatUsersList = useCallback(async () => {
    try {
      const { data: chatUsers } = await getConversationByInboxApi(selectedInbox?.identifier);
      dispatch({
        type: Actions.SET_CHAT,
        payload: {
          chatList: chatUsers,
        },
      });
    } catch (error) {
      console.error(error);
      toastErrorsTranslated(error);
    }
  }, [dispatch, selectedInbox]);

  const getUserMessages = useCallback(async () => {
    if (currentUser?.id) {
      try {
        const { data: messages } = await getMessagesOfConversationApi(currentUser?.id);
        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            currentUserMessages: messages,
          },
        });
      } catch (error) {
        console.error(error);
        toastErrorsTranslated(error);
      }
    }
  }, [currentUser?.id, dispatch]);

  const assignUserToAgent = useCallback(async () => {
    if (currentUser?.id && !currentUser.assignedToAgent) {
      try {
        await assignCurrentAgentToConversation(currentUser?.id);
      } catch (error) {
        console.error(error);
        toastErrorsTranslated(error);
      }
    }
  }, [currentUser]);

  const setUserIfAssigned = useCallback(async () => {
    if (!currentUser && paramsChatBotUserID) {
      try {
        const { data: chatUser } = await getConversationByIdApi(paramsChatBotUserID);

        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            currentUser: chatUser,
          },
        });
      } catch (error) {
        history.push(CHAT_PAGE_ROUTE);
      }
    }
  }, [currentUser, paramsChatBotUserID, dispatch, history]);

  const getInboxes = useCallback(async () => {
    try {
      const { data: inboxes } = await getInboxesOfAgentApi();

      dispatch({
        type: Actions.SET_CHAT,
        payload: { inboxes },
      });
    } catch (error) {
      console.error(error);
      toastErrorsTranslated(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getChatUsersList();
  }, [selectedInbox, getChatUsersList]);

  useEffect(() => {
    getUserMessages();
  }, [getUserMessages]);

  useEffect(() => {
    assignUserToAgent();
  }, [assignUserToAgent]);

  useEffect(() => {
    setUserIfAssigned();
  }, [setUserIfAssigned]);

  useEffect(() => {
    getInboxes();
  }, [getInboxes]);

  // * Remove canned responses by default when entering conversations page
  useEffect(() => {
    removeCannedResponses();
  }, [removeCannedResponses]);
};
