import React from 'react';
import { Form } from 'react-bootstrap';
import { IInputProps } from '../../../../../interfaces/IInputProps';

export const WebclientNumberInput: React.FC<IInputProps> = ({ label, name, register }) => {
  return (
    <Form.Group>
      <p>{label}</p>
      <Form.Control {...register(name)} />
    </Form.Group>
  );
};

export default WebclientNumberInput;
