import React from 'react';
import { Dropdown } from 'react-bootstrap';
import style from '../../../assets/scss/components/chat/chat-panel-header/MoreDropDown.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';

export const MoreDropDown: React.FC = () => {
  const { buttonMore, item, title, list, divider, menu, iconMore, dropdown } = convertKeysToCamelCase(style);

  return (
    <Dropdown className={buttonMore} bsPrefix={buttonMore}>
      <Dropdown.Toggle className={dropdown}>
        <span className={`material-icons  ${iconMore}`}>more_horiz</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className={menu}>
        <Dropdown.Item className={`${item} ${list}`} href="#/action-3">
          <p>Open in new tab</p>
        </Dropdown.Item>
        <Dropdown.Item className={`${item} ${list}`} href="#/action-1">
          <p>Move to trash</p>
        </Dropdown.Item>
        <Dropdown.Item className={`${item} ${list}`} href="#/action-2">
          <p>Mark as spam</p>
        </Dropdown.Item>
        <Dropdown.Divider className={divider} />
        <Dropdown.Item eventKey="4" className={`${item} ${title}`}>
          <p>Block</p>
        </Dropdown.Item>
        <Dropdown.Divider className={divider} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreDropDown;
