import React from 'react';
import MaterialIcon from '../../common/MaterialIcon';

type TProps = {
  title: string;
  onClickInfo?: CallableFunction;
};

export const ContentSubTitle: React.FC<TProps> = ({ title, onClickInfo }) => {
  return (
    <div className="d-flex flex-col align-items-center mt-2">
      <h4 className="m-2">{title}</h4>
      <MaterialIcon icon={'info'} classes={['clickable']} onClick={onClickInfo} />
    </div>
  );
};

export default ContentSubTitle;
