import { Actions } from '../../../constants/actionTypes';
import { pushUnique } from '../../../helpers/baseHelpers';
import { TBlockParamsWithPosition, TBlockWithPosition } from '../../../interfaces/TDesigner';
import { useMainDispatch } from '../../useMainDispatch';
import { useBlocks } from '../block/useBlocks';
import { useBlocksActions } from '../block/useBlocksActions';

export const useFlowActions = () => {
  const { blocks } = useBlocks();
  const { updateBlockContent } = useBlocksActions();
  const dispatch = useMainDispatch();

  const setSelectedBlock = (selectedBlock: TBlockWithPosition) => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { selectedBlock } });
  };

  const setMatchIndex = (index: number) => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { blocksSearchMatchIndex: index } });
  };

  const setSearchString = (input: string) => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { blocksSearchString: input } });
  };

  const setBlockParamsForCreation = (params: TBlockParamsWithPosition) => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { newBlock: params } });
  };

  const connectBlocks = (sourceId?: string | null, targetId?: string | null): boolean => {
    if (!sourceId || !targetId) {
      return false;
    }

    const sourceBlock = blocks?.find(({ id }) => id === sourceId);
    const targetBlock = blocks?.find(({ id }) => id === targetId);

    if (!sourceBlock || !targetBlock) {
      return false;
    }

    if (targetBlock.type !== 'button') {
      if (sourceBlock.type === 'textWithButtons' || sourceBlock.type === 'text') {
        updateBlockContent({
          ...sourceBlock,
          destination: [targetBlock.id],
        });
        return true;
      }
    }

    if (sourceBlock.type === 'textWithButtons') {
      if (targetBlock.type === 'button') {
        updateBlockContent({
          ...sourceBlock,
          contains: pushUnique(targetBlock.id, sourceBlock.contains),
        });
        return true;
      }
    }

    if (sourceBlock.type === 'text') {
      if (targetBlock.type === 'button') {
        updateBlockContent({
          ...sourceBlock,
          type: 'textWithButtons',
          contains: pushUnique(targetBlock.id, []),
        });
        return true;
      }
    }

    return false;
  };

  return {
    setSelectedBlock,
    setBlockParamsForCreation,
    setMatchIndex,
    setSearchString,
    connectBlocks,
  };
};
