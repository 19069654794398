import axios, { AxiosInstance } from 'axios';

import { API_ENDPOINT } from '../../constants/env';
import { DataKeys } from '../../constants/login';

export const axiosDefault = (): AxiosInstance => {
  const company = localStorage.getItem(DataKeys.SELECTED_COMPANY_DATA_KEY);
  const token = localStorage.getItem(DataKeys.ACCESS_TOKEN_DATA_KEY);
  if (!token) {
    return axios.create({
      baseURL: API_ENDPOINT,
    });
  }

  const headers = {
    common: company
      ? {
          Authorization: `Bearer ${token}`,
          'user-company': company,
        }
      : {
          Authorization: `Bearer ${token}`,
        },
  };

  return axios.create({
    baseURL: API_ENDPOINT,
    headers,
  });
};
