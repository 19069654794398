import React from 'react';
import { useBlocksActions } from '../../../../hooks/flow/block/useBlocksActions';
import { useFlow } from '../../../../hooks/flow/flow/useFlow';
import { TBlockWithPosition, TBlocksFormData } from '../../../../interfaces/TDesigner';
import { TextBlockDataModal } from './TextBlockDataModal';

const getFieldsOfBlock = (block?: TBlockWithPosition) => {
  if (!block) {
    return {};
  }

  // TODO: Add more fields here in the future
  if ('textToSend' in block) {
    return {
      fieldName: 'textToSend',
      text: block.textToSend,
    };
  }

  if ('buttonText' in block) {
    return {
      fieldName: 'buttonText',
      text: block.buttonText,
    };
  }

  return {};
};

export const EditTextBlockModal: React.FC = () => {
  const { selectedBlock } = useFlow();
  const { updateBlock, updateBlockContent } = useBlocksActions();

  const saveBlockWithoutPositionDataBasedOnType = (block: TBlockWithPosition, data: TBlocksFormData) => {
    // * Omit positional data from block before saving
    const { verticalPosition: _verticalPosition, horizontalPosition: _horizontalPosition, ...blockProperties } = block;

    // TODO: Expand this for more form data
    const newText = data?.text;

    if (!newText) {
      return blockProperties;
    }

    if (blockProperties.type === 'button') {
      return {
        ...blockProperties,
        buttonText: newText,
      };
    }

    if (blockProperties.type === 'text' || blockProperties.type === 'textWithButtons') {
      return {
        ...blockProperties,
        textToSend: newText,
      };
    }

    return blockProperties;
  };

  const onSubmit = async (blockData: TBlocksFormData) => {
    if (!selectedBlock?.id) {
      return;
    }

    const expandedBlockData = saveBlockWithoutPositionDataBasedOnType(selectedBlock, blockData);

    const updatedBlock = await updateBlock(expandedBlockData);

    if (updatedBlock?.id) {
      updateBlockContent({
        ...updatedBlock,
        verticalPosition: selectedBlock.verticalPosition,
        horizontalPosition: selectedBlock.horizontalPosition,
      });
    }
  };

  const { text, fieldName } = getFieldsOfBlock(selectedBlock);

  if (!selectedBlock || !fieldName) {
    return null;
  }

  return (
    <>
      <TextBlockDataModal
        description={selectedBlock?.id}
        onSubmit={onSubmit}
        blockData={{
          text,
        }}
        fieldName={fieldName}
      />
    </>
  );
};

export default EditTextBlockModal;
