import React from 'react';
import { Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useWebclientSnippet } from '../../../../hooks/settings/webclients/useWebclientSnippet';

export const WebclientSnippetModal: React.FC = () => {
  const { copySnippetToClipboard, snippetContent, snippetType, setSnippetType } = useWebclientSnippet();

  return (
    <Modal.Body className="d-flex flex-column">
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center bg-light border p-1" role="button" onClick={copySnippetToClipboard}>
          Copy
          <span className="material-icons mx-2">content_copy</span>
        </div>
        <ToggleButtonGroup type="radio" name="snippet" value={snippetType} onChange={setSnippetType}>
          <ToggleButton id="tbg-btn-1" value={'simple'}>
            Simple
          </ToggleButton>
          <ToggleButton id="tbg-btn-2" value={'advanced'}>
            Advanced
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <pre className="container bg-light">{snippetContent}</pre>
    </Modal.Body>
  );
};

export default WebclientSnippetModal;
