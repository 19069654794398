import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useCannedResponses } from '../../hooks/chat/useCannedResponses';
import { useCannedResponsesInit } from '../../hooks/chat/useCannedResponsesInit';

export const CannedResponsesSettings: React.FC = () => {
  useCannedResponsesInit();
  const { cannedResponses, setSelectedCannedResponse } = useCannedResponses();
  const { showModal } = useModal();

  return (
    <>
      {cannedResponses?.length ? (
        <>
          <ContentTitle title={'Canned Responses'} onClickInfo={() => showModal('canned-response-info')} />
          <AddButton text={'Add canned response'} onClickAdd={() => showModal('add-canned-response')} />
          <SettingsCardContainer>
            {cannedResponses.map((response, index) => (
              <SettingsCard
                key={index}
                title={`/${response.shorthand}`}
                subtitle={response.message}
                actions={[
                  {
                    icon: 'border_color',
                    action: () => {
                      showModal('edit-canned-response');
                      setSelectedCannedResponse(response);
                    },
                  },
                  {
                    icon: 'delete',
                    action: () => {
                      showModal('delete-canned-response');
                      setSelectedCannedResponse(response);
                    },
                  },
                ]}
              />
            ))}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState
          message={'It is quite empty around here. Add a few canned responses'}
          addButton={() => showModal('add-canned-response')}
        />
      )}
    </>
  );
};
export default CannedResponsesSettings;
