import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/settings-page/EmptyState.module.scss';
import emptyState from '../../assets/images/empty-state.svg';
import AddButton from './AddButton';

type TEmptyStateProps = {
  message?: string;
  addButton?: CallableFunction;
};

export const EmptyState: React.FC<TEmptyStateProps> = ({ message, addButton }) => {
  const { emptyStateWrapper, ctaWrapper } = convertKeysToCamelCase(style);

  return (
    <div className={emptyStateWrapper}>
      <img src={emptyState} />
      <div className={ctaWrapper}>
        <span>{message ?? 'It is quite empty around here. Enlarge your team by adding some agents'}</span>
        {addButton && <AddButton onClickAdd={addButton} />}
      </div>
    </div>
  );
};

export default EmptyState;
