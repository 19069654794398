import React from 'react';
import { Modal, Container } from 'react-bootstrap';

export const WebclientConfigInfoModal: React.FC = () => {
  return (
    <Modal.Body>
      <Container>
        <p>Here are listed the available configurations for your webclient.</p>
        <br></br>
        <p>
          You can add new configurations or edit the ones existing. Each configuration has different HTML snippet which
          can be copied by clicking the snippet icon (the first on on the card). You can set up different look and feel
          for every different configuration or you can also specify different refParams for accessing different sub
          flows.
        </p>
        <br></br>
        <p>General use case for different configs: </p>
        <ul className="mx-5">
          <li>Different style on different pages</li>
          <li>Different sub flows on different pages</li>
          <li>Different greeting text or default window state on different pages</li>
        </ul>
      </Container>
    </Modal.Body>
  );
};

export default WebclientConfigInfoModal;
