import { MaterialIcon } from '../../../components/common/MaterialIcon';
import { useChatInput } from '../../../hooks/chat/useChatInput';
import styles from '../../../assets/scss/components/chat/ChatInput.module.scss';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import { useCannedResponses } from '../../../hooks/chat/useCannedResponses';
import { ChatEmojiPicker } from './ChatEmojiPicker';

export const ChatInputFooter = () => {
  const { chatMode, sendMessage } = useChatInput();
  const { toggleCannedResponsesMode } = useCannedResponses();

  const { chatInputBottom, active, inputIcons, icon, iconAttach, iconReply, buttonSend, iconSend } =
    convertKeysToCamelCase(styles);

  const noteChatClassName = getCSS(chatMode === 'note', active);

  return (
    <>
      <div className={`d-flex justify-content-between ${chatInputBottom} ${noteChatClassName}`}>
        <div className={`d-flex justify-content-between ${inputIcons}`}>
          <ChatEmojiPicker />
          <MaterialIcon icon={'attach_file'} classes={[icon, iconAttach]} />
          <MaterialIcon icon={'quickreply'} classes={[icon, iconReply]} onClick={() => toggleCannedResponsesMode()} />
        </div>

        <div onClick={() => sendMessage()} role="button" className={`btn btn-primary ${buttonSend} `}>
          <p>{chatMode === 'reply' ? 'Send' : 'Add Note'}</p>
          <MaterialIcon icon={'send'} classes={[icon, iconSend]} />
        </div>
      </div>
    </>
  );
};
