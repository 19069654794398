import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/SettingsSidebar.module.scss';
import { Accordion } from 'react-bootstrap';
import { SettingsSidebarRoutes } from '../../routes/SettingsSidebarRoutes';
import { SidebarElement } from './SettingsSidebarElement';

export const SettingsSidebar: React.FC = () => {
  const { settingsSidebarWrapper } = convertKeysToCamelCase(style);

  return (
    <Accordion className={settingsSidebarWrapper} bsPrefix={settingsSidebarWrapper}>
      {SettingsSidebarRoutes.map((element, index) => {
        const { elements, icon, title } = element;
        return <SidebarElement key={index} index={index.toString()} icon={icon} title={title} elements={elements} />;
      })}
    </Accordion>
  );
};
