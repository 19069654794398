import { useChatInput } from '../../../hooks/chat/useChatInput';
import styles from '../../../assets/scss/components/chat/ChatInput.module.scss';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';

export const ChatInputHeader = () => {
  const { chatMode, setInputMode } = useChatInput();

  const { chatInputHeader, inputTabs, tab, reply, note, formatSize, active } = convertKeysToCamelCase(styles);

  const replyChatClassName = getCSS(chatMode === 'reply', active);
  const noteChatClassName = getCSS(chatMode === 'note', active);

  return (
    <div className={`d-flex justify-content-between ${chatInputHeader}`}>
      <div className={`d-flex flex-row ${inputTabs}`}>
        <div onClick={() => setInputMode('reply')} role="button" className={`${tab} ${reply} ${replyChatClassName}`}>
          <p>Reply</p>
        </div>
        <div onClick={() => setInputMode('note')} role="button" className={`${tab} ${note} ${noteChatClassName}`}>
          <p>Private note</p>
        </div>
      </div>
      <div className={`${tab} ${formatSize}`}>
        <span role="button" className="material-icons">
          format_size
        </span>
      </div>
    </div>
  );
};
