import { IUserLoginPayload } from '../interfaces/ILogin';

export const formatLoginPayload = (payload: IUserLoginPayload): string => {
  const email = encodeURIComponent(payload.email);
  const password = encodeURIComponent(payload.password);
  const token = payload.token ? encodeURIComponent(payload.token) : null;

  const emailParam = `&username=${email}`;
  const passwordParam = `&password=${password}`;
  const tokenParam = token ? `&token=${token}` : '';

  return `grant_type=password${emailParam}${passwordParam}${tokenParam}&client_id=conversed-admin-panel`;
};

export const formatRefreshTokenPayload = (token: string): string => {
  return `grant_type=refresh_token&refresh_token=${encodeURIComponent(token)}&client_id=conversed-admin-panel`;
};
