import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import ContentTitle from './common/ContentTitle';
import MaterialIcon from '../common/MaterialIcon';
import { Form, Row, Dropdown, Col, Button, Table } from 'react-bootstrap';

export const SettingsPlatform: React.FC = () => {
  const {
    settingsForm,
    sectionCategory,
    inputForm,
    buttonSave,
    form,
    dropDown,
    checkBox,
    arrow,
    item,
    list,
    menu,
    formCheck,
    table,
    inputGroup,
    input,
  } = convertKeysToCamelCase(style);

  return (
    <div className={settingsForm}>
      <ContentTitle title={'Platform settings'} />
      <div className={`${sectionCategory} ${form}`}>
        <h5>Settings category title</h5>
        <Form>
          <Row className={`${inputGroup}`}>
            <Form.Group as={Col} className={`${inputForm}`}>
              <p>Lorem Ipsum</p>
              <Form.Control className={input} />
            </Form.Group>
            <Form.Group as={Col} className={`${inputForm}`}>
              <p>Lorem Ipsum</p>
              <Form.Control className={input} />
            </Form.Group>
          </Row>
        </Form>
      </div>
      <div className={`${sectionCategory} ${dropDown}`}>
        <h5>Settings category title</h5>
        <Dropdown>
          <Dropdown.Toggle className="btn btn-stroke">
            <p>Lorem Ipsum</p> <MaterialIcon classes={[arrow]} icon={'arrow_drop_down'} />
          </Dropdown.Toggle>
          <Dropdown.Menu className={menu}>
            <Dropdown.Item className={`${item} ${list}`} href="#/action-1">
              <p>Action</p>
            </Dropdown.Item>
            <Dropdown.Item className={`${item} ${list}`} href="#/action-2">
              <p>Another action</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={`${sectionCategory} ${checkBox}`}>
        <h5>Settings category title</h5>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Dolor sit amet consectetur adip" className={`${formCheck}`} />
          <Form.Check type="checkbox" label="Ligula ullamcorper" className={`${formCheck}`} />
          <Form.Check type="checkbox" label="Faucibus in ornare" className={`${formCheck}`} />
        </Form.Group>
      </div>
      <div className={`${sectionCategory} ${table}`}>
        <Table responsive>
          <thead>
            <tr>
              {Array.from({ length: 5 }).map((_, index) => (
                <th key={index}>
                  <p> Table heading</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Array.from({ length: 5 }).map((_, index) => (
                <td key={index}>
                  <p>Table cell</p>
                </td>
              ))}
            </tr>
            <tr>
              {Array.from({ length: 5 }).map((_, index) => (
                <td key={index}>
                  <p>Table cell</p>
                </td>
              ))}
            </tr>
            <tr>
              {Array.from({ length: 5 }).map((_, index) => (
                <td key={index}>
                  <p>Table cell</p>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
      <div className={`${sectionCategory} ${buttonSave}`}>
        <Button type="submit" className={`btn btn-primary ${buttonSave} `}>
          <span> Save changes</span>
        </Button>
      </div>
    </div>
  );
};

export default SettingsPlatform;
