import React from 'react';
import { Button, Modal, Form, Row, Col, Container } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/designer/modal/ModalForm.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { TBlocksFormData } from '../../../../interfaces/TDesigner';

type TProps = {
  description?: string;
  blockData?: Partial<TBlocksFormData>;
  fieldName: string;
  onSubmit: (data: TBlocksFormData) => void;
};

//TODO: Dynamically build up the form based on data
export const TextBlockDataModal: React.FC<TProps> = ({ description, blockData, fieldName, onSubmit }) => {
  const { inputForm, inputGroup, input, modalSection, modalInput, footer } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm({ shouldUseNativeValidation: true, defaultValues: blockData });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: TBlocksFormData) => {
    onSubmit(data);
    hideModal();
  };

  if (!blockData?.text) {
    return <></>;
  }

  const { text } = blockData;
  const languages = Object.keys(text);

  return (
    <>
      <Modal.Body>
        <div>
          <div className={`${modalSection} ${modalInput}`}>
            <h5>{description}</h5>
            <Form onSubmit={handleSubmit<any>(hideAndSubmit)}>
              <Row className={`${inputGroup}`}>
                <Container fluid>
                  {languages.map((language, index) => {
                    return (
                      <Row key={index}>
                        <Col>
                          <Form.Group className={`${inputForm}`}>
                            <p>
                              {fieldName} - {language.toUpperCase()}
                            </p>
                            <Form.Control
                              as={'textarea'}
                              className={input}
                              {...register(`text.${language}`, { required: 'Please enter the text for block' })}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    );
                  })}
                </Container>
              </Row>
              <div className={`${footer}`}>
                <Modal.Footer>
                  <Button type="submit">Save</Button>
                </Modal.Footer>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default TextBlockDataModal;
