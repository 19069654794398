import React from 'react';
import { Button, Container, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Config } from '../../../../api/dto/webclient.dto';
import { useModal } from '../../../../hooks/modal/useModal';
import WebclientColorInput from './input/WebclientColorInput';
import { flattenObject } from '../../../../helpers/objectHelpers';
import WebclientTextInput from './input/WebclientTextInput';
import WebclientNumberInput from './input/WebclientNumberInput';
import WebclientDropDownInput from './input/WebclientDropDownInput';
import WebclientImageInput from './input/WebclientImageInput';
import WebclientInputGroup from './WebclientInputGroup';
import WebclientBooleanInput from './input/WebclientBooleanInput';
import { WebclientFormData, WebclientFormInputData } from '../../../../interfaces/IWebclient';

interface Props {
  data: Config;
  name: string;
  onSubmit: (data: WebclientFormData) => void;
}

export const WebclientDataModal: React.FC<Props> = ({ data, name, onSubmit }) => {
  const { register, handleSubmit, control } = useForm<any>({
    shouldUseNativeValidation: true,
    defaultValues: {
      name,
      ...flattenObject(data),
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: WebclientFormInputData) => {
    onSubmit({
      name: data.name,
      config: { ...{ ...data, name: undefined } },
    });
    hideModal();
  };

  return (
    <Modal.Body>
      <Form onSubmit={handleSubmit<WebclientFormInputData>(hideAndSubmit)}>
        <Row>
          <Container fluid>
            <Form.Group>
              <WebclientInputGroup title="Name">
                <WebclientTextInput label="" name="name" register={register} />
              </WebclientInputGroup>

              <WebclientInputGroup title="Texts">
                <WebclientTextInput label="Title" name="options.title" register={register} />
                <WebclientTextInput label="Subtitle" name="options.subtitle" register={register} />
                <WebclientTextInput label="Welcome message" name="options.welcomeMessage" register={register} />
                <WebclientTextInput label="Message placeholder" name="options.messagePlaceholder" register={register} />
                <WebclientTextInput label="Button text" name="options.greeting.buttonText" register={register} />
              </WebclientInputGroup>

              <WebclientInputGroup title="Options">
                <WebclientTextInput label="Ref param" name="options.refParam" register={register} />
                {/* TODO add menuTemplate */}
                {/* menuTemplate: MenuTemplate;  */}
                <WebclientTextInput label="Language" name="options.language" register={register} />

                <WebclientDropDownInput
                  label="Default window state"
                  values={['greeting', 'fullscreen', 'open', 'closed']}
                  name="options.defaultState"
                  register={register}
                />
                <WebclientDropDownInput
                  label="Logo type"
                  values={['circle', 'square']}
                  name="options.icons.logo.type"
                  register={register}
                />
                <WebclientDropDownInput
                  label="Header type"
                  values={['slim', 'normal', 'noimage']}
                  name="options.headerType"
                  register={register}
                />
                <WebclientNumberInput label="Greeting timeout" name="options.greetingTimeout" register={register} />
                <WebclientBooleanInput label="Show close button" name="options.showCloseButton" register={register} />
                <WebclientBooleanInput label="Enable attachments" name="options.useAttachment" register={register} />
              </WebclientInputGroup>

              <WebclientInputGroup title="Icons">
                <WebclientImageInput label="Chat icon - open" name="options.icons.chatIcon.open" register={register} />
                <WebclientImageInput
                  label="Chat icon - closed"
                  name="options.icons.chatIcon.closed"
                  register={register}
                />
                <WebclientImageInput label="Logo" name="options.icons.logo.path" register={register} />
                <WebclientImageInput label="Speech icon" name="options.icons.speechIcon" register={register} />
                <WebclientImageInput
                  label="Greeting message icon"
                  name="options.greeting.messageIcon"
                  register={register}
                />
              </WebclientInputGroup>

              <WebclientInputGroup title="Header colors">
                <WebclientColorInput label="Background" name="scheme.header.headerColor" control={control} />
                <WebclientColorInput label="Title" name="scheme.header.headerTitleColor" control={control} />
                <WebclientColorInput label="Subtitle" name="scheme.header.headerSubtitleColor" control={control} />
                <WebclientColorInput label="Window" name="scheme.header.windowColor" control={control} />
                <WebclientColorInput label="Message bar" name="scheme.header.messageBarColor" control={control} />
              </WebclientInputGroup>

              <WebclientInputGroup title="Message colors">
                <WebclientColorInput
                  label="Message bubble background (bot)"
                  name="scheme.message.messageBubbleColor"
                  control={control}
                />
                <WebclientColorInput
                  label="Message bubble text (bot)"
                  name="scheme.message.messageBubbleTextColor"
                  control={control}
                />
                <WebclientColorInput
                  label="Message bubble background (user)"
                  name="scheme.message.personalMessageBubbleColor"
                  control={control}
                />
                <WebclientColorInput
                  label="Message bubble text (user)"
                  name="scheme.message.personalMessageBubbleTextColor"
                  control={control}
                />
                <WebclientColorInput label="Button edge" name="scheme.message.buttonEdgeColor" control={control} />
                <WebclientColorInput label="Button text" name="scheme.message.buttonTextColor" control={control} />
                <WebclientColorInput label="Button hover" name="scheme.message.buttonHoverColor" control={control} />
                <WebclientColorInput
                  label="Carousel background"
                  name="scheme.message.carouselBackgroundColor"
                  control={control}
                />
                <WebclientColorInput
                  label="Carousel button background"
                  name="scheme.message.carouselButtonColor"
                  control={control}
                />
                <WebclientColorInput label="Carousel text" name="scheme.message.carouselTextColor" control={control} />
                <WebclientColorInput
                  label="Carousel button text"
                  name="scheme.message.carouselButtonTextColor"
                  control={control}
                />
                <WebclientColorInput
                  label="Submit button color"
                  name="scheme.message.submitButtonColor"
                  control={control}
                />
              </WebclientInputGroup>

              <WebclientInputGroup title="Greeting colors">
                <WebclientColorInput label="Title" name="scheme.greeting.titleColor" control={control} />
                <WebclientColorInput label="Subtitle" name="scheme.greeting.subtitleColor" control={control} />
                <WebclientColorInput label="Button text" name="scheme.greeting.buttonTextColor" control={control} />
                <WebclientColorInput label="Button background" name="scheme.greeting.buttonColor" control={control} />
                <WebclientColorInput label="Background" name="scheme.greeting.backgroundColor" control={control} />
              </WebclientInputGroup>
            </Form.Group>
          </Container>

          <Modal.Footer>
            <Button type="submit">Save</Button>
          </Modal.Footer>
        </Row>
      </Form>
    </Modal.Body>
  );
};

export default WebclientDataModal;
