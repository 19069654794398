import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TDTOGetLabelsApi, TDTOPostLabelsApi, TDTODeleteLabelsApi, TDTOPutLabelsApi } from './dto/label.dto';

export const getLabelsApi: TDTOGetLabelsApi = () => axiosInterceptAccessTokenExpiry().get(ENDPOINTS.LABEL);

export const createLabelApi: TDTOPostLabelsApi = (data) =>
  axiosInterceptAccessTokenExpiry().post(ENDPOINTS.LABEL, data);

export const deleteLabelApi: TDTODeleteLabelsApi = (id) =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.LABEL}/${id}`);

export const updateLabelApi: TDTOPutLabelsApi = (id, data) =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.LABEL}/${id}`, {
    ...data,
  });
