import { Handle } from 'react-flow-renderer';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { getHandlePositionByFlowDirection } from '../../../helpers/flowHelper';
import style from '../../../assets/scss/components/designer/nodes/FlowNode.module.scss';
import { DatabaseUpdate } from '../../../interfaces/blocks/DatabaseUpdate';
import { DatabaseRead } from '../../../interfaces/blocks/DatabaseRead';
import { DatabaseDelete } from '../../../interfaces/blocks/DatabaseDelete';
import { splitIntoLines } from '../../../helpers/domHelper';
import { useFlow } from '../../../hooks/flow/flow/useFlow';

interface IProps {
  data: DatabaseUpdate | DatabaseRead | DatabaseDelete;
}

const getContent = (block: DatabaseUpdate | DatabaseRead | DatabaseDelete) => {
  if (!block?.type) {
    return '';
  }

  if (block.type === 'databaseUpdate') {
    switch (block.dataSource) {
      case 'staticBlockData': {
        return `${block.updateData}: ${block.dataToSave}`;
      }
      case 'userDynamicButtonInput':
      case 'userInput':
        return `userInput -> ${block.updateData}`;
      default:
        return '';
    }
  }

  if (block.type === 'databaseRead') {
    return Object.entries(block.prepareData).reduce((acc, [valKey, valValue]) => {
      return `${acc}\n${valKey} <- ${valValue}`;
    }, '');
  }
};

export const DataNode: React.FC<IProps> = ({ data }) => {
  const { id } = data;

  const { node, nodeSeparator, nodeBody, nodeTitle } = convertKeysToCamelCase(style);

  const { flowDirection } = useFlow();
  const { start, middle, end } = getHandlePositionByFlowDirection(flowDirection);
  const body = getContent(data);

  return (
    <div className={node}>
      <Handle type="target" position={start} id="start" />
      <div className={nodeTitle}>
        <p>
          <strong>{id}</strong>
        </p>
      </div>
      <hr className={nodeSeparator} />
      <div className={nodeBody}>{splitIntoLines(body)}</div>
      <Handle type="source" position={middle} id="mid" />
      <Handle type="source" position={end} id="end" />
    </div>
  );
};
