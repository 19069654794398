import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/CustomControls.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { GeneralTooltip } from '../../common/GeneralTooltip';
import { useFlowControls } from '../../../hooks/flow/useFlowControls';

export const DownloadBlocksButton: React.FC = () => {
  const { button } = convertKeysToCamelCase(style);
  const { downloadBlocks } = useFlowControls();

  return (
    <GeneralTooltip id={'tooltip-flow-download-blocks-button'} text={'Download blocks to JSON'}>
      <div className={button} onClick={() => downloadBlocks()}>
        <MaterialIcon icon={'download'} />
      </div>
    </GeneralTooltip>
  );
};
