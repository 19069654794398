import { useEffect } from 'react';
import { useCompanySelector } from '../../useCompanySelector';
import { useWebclientActions } from './useWebclientActions';

export const useWebclientInit = () => {
  const { fetchWebclientConfigs } = useWebclientActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchWebclientConfigs();
  }, [fetchWebclientConfigs, selectedCompany]);
};
