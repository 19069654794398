import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useWebclientInit } from '../../hooks/settings/webclients/useWebclientInit';
import { useWebclient } from '../../hooks/settings/webclients/useWebclient';
import ContentSubTitle from './common/ContentSubTitle';
import MaterialIcon from '../common/MaterialIcon';
import { useCollapsedList } from '../../hooks/useCollapsedList';

export const WebclientSettings: React.FC = () => {
  useWebclientInit();
  const { webclient, setSelectedWebclientConfig, setSelectedWhitelistedDomain } = useWebclient();
  const { showModal } = useModal();

  const {
    elementsToShow: domains,
    showAll,
    setShowAll,
    hasMoreElements,
  } = useCollapsedList<string>(webclient.whitelistedDomains);

  return (
    <>
      {!!webclient.uuid ? (
        <>
          <ContentTitle title={'Webclient'} onClickInfo={() => showModal('webclient-info')} />
          <ContentSubTitle title={'Whitelisted domains'} onClickInfo={() => showModal('webclient-whitelist-info')} />
          <AddButton text={'Add new domain'} onClickAdd={() => showModal('add-webclient-whitelist')} />
          <div className="container mx-5">
            {!!domains?.length ? (
              <ul>
                {domains.map((domain) => (
                  <li key={domain} className="d-flex align-items-center">
                    - {domain}{' '}
                    <div
                      role="button"
                      onClick={() => {
                        setSelectedWhitelistedDomain(domain);
                        showModal('delete-webclient-whitelist');
                      }}
                    >
                      <MaterialIcon classes={['m-1']} icon="delete" />
                    </div>
                  </li>
                ))}
                {hasMoreElements && (
                  <div role="button" onClick={() => setShowAll((showAll) => !showAll)}>
                    {showAll ? <div>Hide</div> : <div>Show all</div>}
                  </div>
                )}
              </ul>
            ) : (
              <div>No whitelisted domains</div>
            )}
          </div>
          <br></br>
          <ContentSubTitle title={'Available configurations'} onClickInfo={() => showModal('webclient-config-info')} />
          <AddButton text={'Add new configuration'} onClickAdd={() => showModal('create-webclient-config')} />
          <SettingsCardContainer>
            {webclient.configs.map((webclientConfig) => (
              <SettingsCard
                key={webclientConfig.identifier}
                title={webclientConfig.name}
                subtitle=""
                actions={[
                  {
                    icon: 'text_snippet',
                    action: () => {
                      setSelectedWebclientConfig(webclientConfig.identifier);
                      showModal('webclient-snippet');
                    },
                  },
                  {
                    icon: 'border_color',
                    action: () => {
                      setSelectedWebclientConfig(webclientConfig.identifier);
                      showModal('edit-webclient-config');
                    },
                  },
                  {
                    icon: 'delete',
                    action: () => {
                      setSelectedWebclientConfig(webclientConfig.identifier);
                      showModal('delete-webclient-config');
                    },
                  },
                ]}
              />
            ))}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState
          message={'There is no webclient integrated currently. You can setup an integration in few minutes'}
          addButton={() => alert('TODO: Implement')}
        />
      )}
    </>
  );
};
export default WebclientSettings;
