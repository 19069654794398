import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { IColorInputProps } from '../../../../../interfaces/IInputProps';

export const WebclientColorInput: React.FC<IColorInputProps> = ({ label, name, control }) => {
  return (
    <Form.Group className="d-flex flex-row align-items-center">
      <p>{label}:</p>

      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => <Form.Control type="color" id={name} {...field} />}
      />
    </Form.Group>
  );
};

export default WebclientColorInput;
