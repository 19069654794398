import { TSettingsSidebarElement } from '../../interfaces/ISettings';
import { AgentSettings } from '../settings-page/AgentSettings';
import { InboxSettings } from '../settings-page/InboxSettings';
import { LabelSettings } from '../settings-page/LabelSettings';
import { CannedResponsesSettings } from '../settings-page/CannedResponsesSettings';
import SettingsPlatform from '../settings-page/SettingsPlatform';
import WebclientSettings from '../settings-page/WebclientSettings';

export const SettingsSidebarRoutes: TSettingsSidebarElement[] = [
  {
    icon: 'supervised_user_circle',
    title: 'Agents',
    elements: [
      {
        text: 'Settings',
        destination: 'agents',
        component: AgentSettings,
      },
      {
        text: 'Dapibus ac facilisis in',
      },
      {
        text: 'Morbi leo risus',
      },
      {
        text: 'Porta ac consectetur ac',
      },
      {
        text: 'Morbi leo risus',
      },
    ],
  },
  // {
  //   icon: 'people_alt',
  //   title: 'Teams',
  //   elements: [
  //     {
  //       text: 'Cras justo odio',
  //     },
  //     {
  //       text: 'Dapibus ac facilisis in',
  //     },
  //     {
  //       text: 'Morbi leo risus',
  //     },
  //     {
  //       text: 'Porta ac consectetur ac',
  //     },
  //   ],
  // },
  {
    icon: 'move_to_inbox',
    title: 'Inboxes',
    elements: [
      {
        text: 'Settings',
        destination: 'inboxes',
        component: InboxSettings,
      },
      {
        text: 'Dapibus ac facilisis in',
      },
      {
        text: 'Morbi leo risus',
      },
      {
        text: 'Porta ac consectetur ac',
      },
    ],
  },
  {
    icon: 'bookmark',
    title: 'Labels',
    elements: [
      {
        text: 'Settings',
        destination: 'labels',
        component: LabelSettings,
      },
      {
        text: 'Dapibus ac facilisis in',
      },
      {
        text: 'Morbi leo risus',
      },
      {
        text: 'Porta ac consectetur ac',
      },
    ],
  },
  {
    icon: 'quickreply',
    title: 'Canned Responses',
    elements: [
      {
        text: 'Settings',
        destination: 'canned-responses',
        component: CannedResponsesSettings,
      },
      {
        text: 'Dapibus ac facilisis in',
      },
      {
        text: 'Morbi leo risus',
      },
      {
        text: 'Porta ac consectetur ac',
      },
    ],
  },
  {
    icon: 'integration_instructions',
    title: 'Integrations',
    elements: [
      {
        text: 'Webclient',
        destination: 'webclient',
        component: WebclientSettings,
      },
      {
        text: 'Facebook',
      },
      {
        text: 'Viber',
      },
      {
        text: 'KakaoTalk',
      },
      {
        text: 'WhatsApp',
      },
      {
        text: 'Microsoft Teams',
      },
    ],
  },
  {
    icon: 'settings',
    title: 'Platform Settings',
    elements: [
      {
        text: 'Cras justo odio',
        destination: 'platform',
        component: SettingsPlatform,
      },
      {
        text: 'Dapibus ac facilisis in',
      },
      {
        text: 'Morbi leo risus',
      },
      {
        text: 'Porta ac consectetur ac',
      },
    ],
  },
];
