import React from 'react';

interface IButtonProps {
  text: string;
  buttonType: 'primary' | 'secondary';
  type?: 'button' | 'submit' | 'reset';
}

export const GenericButton: React.FC<IButtonProps> = ({ text, buttonType, type }) => {
  const buttonClass = buttonType === 'primary' ? 'btn-primary' : 'btn-secondary';

  return (
    <button className={`btn ${buttonClass}`} type={type}>
      {text}
    </button>
  );
};
