import { ArrowHeadType, Edge, Elements as FlowElements, Node, Position } from 'react-flow-renderer';
import { EFlowDirection } from '../interfaces/EFlowDirection';
import {
  TBlocksConfigWithPositions,
  TBlockWithPosition,
  TGetLoopBackStepEdge,
  TNodeType,
} from '../interfaces/TDesigner';
export const defaultNodePosition = { x: 0, y: 0 };

const createEdgesBetweenNodes = (blocks: TBlocksConfigWithPositions) => {
  const edges: Edge[] = [];
  blocks.forEach((block) => {
    if ('destination' in block) {
      block?.destination?.forEach((destination: string) => {
        if (!destination) return;
        const edge = {
          id: `${block.id}-${destination}`,
          type: 'loopbackStep',
          source: block.id,
          sourceHandle: 'end',
          style: { stroke: '#000' },
          arrowHeadType: ArrowHeadType.ArrowClosed,
          target: destination,
        };
        edges.push(edge);
      });
    }

    if ('contains' in block) {
      block?.contains?.forEach((destination: string) => {
        if (!destination) return;
        const edge = {
          id: `${block.id}-${destination}`,
          source: block.id,
          target: destination,
          type: 'loopbackStep',
          sourceHandle: 'end',
          style: { stroke: '#f6ab6c' },
          arrowHeadType: ArrowHeadType.ArrowClosed,
        };
        edges.push(edge);
      });
    }

    // Handle decider routing
    if (block.type === 'Decider') {
      block.params.possibleDestinations.map((possibleDestination) => {
        const destination = possibleDestination.blockId;

        const edge = {
          id: `${block.id}-${destination}`,
          source: block.id,
          type: 'loopbackStep',
          sourceHandle: 'end',
          target: destination,
          style: { stroke: '#daddac' },
          arrowHeadType: ArrowHeadType.ArrowClosed,
        };
        edges.push(edge);
      });
    }

    if ('validator' in block) {
      if (!block?.validator?.errorDestination) {
        return;
      }

      block.validator.errorDestination.map((destination: string, index: number) => {
        if (!destination) return;
        //TODO: Fix identical edges
        const edge = {
          id: `${block.id}-${destination}-${index}`,
          source: block.id,
          type: 'loopbackStep',
          target: destination,
          sourceHandle: 'mid',
          style: { stroke: '#33daca' },
          arrowHeadType: ArrowHeadType.ArrowClosed,
        };
        edges.push(edge);
      });
    }

    // TODO: Handle other routing methods
  });

  return edges;
};

const getNodeTypeByBlockType = (block: TBlockWithPosition): TNodeType => {
  switch (block.type) {
    case 'text':
    case 'textWithButtons':
      return 'textNode';
    case 'button':
      return 'buttonNode';
    case 'databaseUpdate':
    case 'databaseRead':
    case 'databaseDelete':
      return 'dataNode';
    case 'Decider':
      return 'routeNode';
    default:
      return 'flowNode';
  }
};

export const getBlockTypeByNode = (nodeType: TNodeType) => {
  switch (nodeType) {
    case 'textNode':
      return 'text';
    case 'buttonNode':
      return 'button';
    case 'dataNode':
      return 'databaseUpdate';
    case 'routeNode':
      return 'Decider';
    case 'flowNode':
      return 'text';
  }
};

const translateBlockToReactFlowNode = (block: TBlockWithPosition): Node<TBlockWithPosition> => {
  const { id } = block;

  return {
    id: id,
    type: getNodeTypeByBlockType(block),
    data: block,
    position: defaultNodePosition,
  };
};

export const getHandlePositionByFlowDirection = (flowDirection: EFlowDirection) => {
  if (flowDirection === EFlowDirection.Vertical) {
    return {
      start: Position.Top,
      end: Position.Bottom,
      middle: Position.Right,
    };
  }

  return {
    start: Position.Left,
    end: Position.Right,
    middle: Position.Bottom,
  };
};

const getNodeDimensions = (nodes: Node[]) =>
  nodes.map((node) => {
    if (!node?.__rf) {
      return {
        height: 1,
        width: 1,
      };
    }

    return {
      height: node.__rf.height ?? 1,
      width: node.__rf.width ?? 1,
    };
  });

export const getLoopbackStepEdge = (options: TGetLoopBackStepEdge) => {
  const { source, target, sourceXYP, targetXYP } = options;
  const { x: sourceX, y: sourceY } = sourceXYP;
  const { x: targetX, y: targetY } = targetXYP;

  const sourceIsOnLeftSide = sourceX > targetX;

  const padding = 24;
  const startPoint = `${sourceX},${sourceY}`;
  const endPoint = `${targetX},${targetY}`;

  const [{ width: sourceWidth, height: sourceHeight }, { height: targetHeight }] = getNodeDimensions([source, target]);

  //*p1 is slightly under source
  const p1 = sourceIsOnLeftSide
    ? `${sourceX},${sourceY + sourceHeight / 2 + padding}`
    : `${sourceX},${sourceY + sourceHeight / 2 + padding}`;

  //*p2 is on either the bottom left or bottom right side from source
  const p2 = sourceIsOnLeftSide
    ? `${sourceX - sourceWidth / 2 - padding},${sourceY + sourceHeight / 2 + padding}`
    : `${sourceX + sourceWidth / 2 + padding},${sourceY + sourceHeight / 2 + padding}`;

  //*p3 is on either the top left or top right side from target
  const p3 = sourceIsOnLeftSide
    ? `${sourceX - sourceWidth / 2 - padding},${targetY - targetHeight / 2 - padding}`
    : `${sourceX + sourceWidth / 2 + padding},${targetY - targetHeight / 2 - padding}`;

  //*p4 is slightly on top of target
  const p4 = sourceIsOnLeftSide
    ? `${targetX},${targetY - targetHeight / 2 - padding}`
    : `${targetX},${targetY - targetHeight / 2 - padding}`;

  //* Draw 4 perpendicular lines from startPoint to endpoint
  const d1 = `M${startPoint} ${p1} ${p2}`;
  const d2 = `M${p2} ${p3} ${p3}`;
  const d3 = `M${p3} ${p4} ${endPoint}`;

  return `${d1}${d2}${d3}`;
};

export const convertBlocksToNodes = (blocks: TBlocksConfigWithPositions) =>
  blocks.filter((block) => block.type !== 'version').map(translateBlockToReactFlowNode);

export const convertBlocksToNodesWithEdges = (blocks: TBlocksConfigWithPositions): FlowElements<TBlockWithPosition> => [
  ...convertBlocksToNodes(blocks),
  ...createEdgesBetweenNodes(blocks),
];
