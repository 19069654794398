import { useCallback } from 'react';
import {
  getCannedResponsesApi,
  createCannedResponseApi,
  deleteCannedResponseApi,
  updateCannedResponseApi,
} from '../../api/cannedResponses.api';
import { Actions } from '../../constants/actionTypes';
import { addValueToList, removeValueFromList, replaceValueInList } from '../../helpers/baseHelpers';
import { TCannedResponse, TCannedResponseUpdateable } from '../../interfaces/TChat';
import { useMainDispatch } from '../useMainDispatch';
import { useCannedResponses } from './useCannedResponses';

export const useCannedResponsesActions = () => {
  const { cannedResponses } = useCannedResponses();
  const dispatch = useMainDispatch();

  const createCannedResponse = useCallback(
    async (cannedResponse: TCannedResponseUpdateable) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        const { data: newCannedResponse } = await createCannedResponseApi(cannedResponse);

        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            cannedResponses: addValueToList<TCannedResponse>(newCannedResponse, cannedResponses),
          },
        });
      } catch (error) {
        // TODO: Handle errors
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [cannedResponses, dispatch],
  );

  const getCannedResponses = useCallback(async () => {
    const { data: cannedResponses } = await getCannedResponsesApi();

    dispatch({
      type: Actions.SET_CHAT,
      payload: {
        cannedResponses: cannedResponses,
      },
    });
  }, [dispatch]);

  const deleteCannedResponse = useCallback(
    async (cannedResponseId: TCannedResponse['identifier']) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await deleteCannedResponseApi(cannedResponseId);

        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            cannedResponses: removeValueFromList<TCannedResponse>(
              (cannedResponse) => cannedResponse.identifier === cannedResponseId,
              cannedResponses,
            ),
          },
        });
      } catch (error) {
        // TODO: Handle errors
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [cannedResponses, dispatch],
  );

  const editCannedResponse = useCallback(
    async (
      cannedResponseId: TCannedResponse['identifier'],
      cannedResponseData: Omit<TCannedResponse, 'identifier'>,
    ) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await updateCannedResponseApi(cannedResponseId, cannedResponseData);

        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            cannedResponses: replaceValueInList<TCannedResponse>(
              { identifier: cannedResponseId, ...cannedResponseData },
              cannedResponses ?? [],
              (cannedResponse) => cannedResponse.identifier === cannedResponseId,
            ),
          },
        });
      } catch (error) {
        // TODO: Handle errors
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [cannedResponses, dispatch],
  );

  const removeCannedResponses = useCallback(() => {
    dispatch({ type: Actions.SET_CHAT, payload: { cannedResponses: [] } });
  }, [dispatch]);

  return { createCannedResponse, getCannedResponses, deleteCannedResponse, editCannedResponse, removeCannedResponses };
};
