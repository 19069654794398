import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { ENDPOINTS } from '../constants/apiEndpoints';
import { TGeneralResponse } from './dto/common.dto';

export const getConversationByInboxApi = (inbox?: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.CONVERSATIONS, { params: { inbox } });

export const getConversationByIdApi = (conversationId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.CONVERSATIONS}/${conversationId}`);

export const getConversationLabelsByIdApi = (conversationId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/labels`);

export const getMessagesOfConversationApi = (conversationId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/messages`);

export const assignCurrentAgentToConversation = (conversationId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/agent`);

export const assignInboxToConversation = (conversationId: string, inboxId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/inbox`, { inboxId });
