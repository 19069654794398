import { meApi } from '../../api/userApi';
import { useCallback, useEffect, useState } from 'react';
import { Actions } from '../../constants/actionTypes';
import { toastErrorsTranslated } from '../../helpers/toastErrors';
import { useMainDispatch } from '../useMainDispatch';
import { useLogin } from '../auth/useLogin';
import { DataKeys } from '../../constants/login';
import { TCompany } from '../../interfaces/IAuth';

const getSelectedCompany = (companies: TCompany[]) => {
  if (companies.length === 1) {
    const [company] = companies;
    localStorage.setItem(DataKeys.SELECTED_COMPANY_DATA_KEY, company.identifier);
    return company;
  }

  const selectedCompanyId = localStorage.getItem(DataKeys.SELECTED_COMPANY_DATA_KEY);

  return companies.find((company) => company.identifier === selectedCompanyId);
};

export const useCurrentUserInit = () => {
  const dispatch = useMainDispatch();
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading');
  const { logout } = useLogin();

  const getLoggedInUser = useCallback(async () => {
    try {
      const { data: userData } = await meApi();

      const selectedCompany = getSelectedCompany(userData.companies);

      dispatch({
        type: Actions.SET_USER,
        payload: {
          currentUser: {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            selectedCompanyId: selectedCompany?.identifier,
            companies: userData.companies.map((company) => ({
              identifier: company.identifier,
              name: company.name,
              permissions: company.permissions,
              skills: company.skills,
              icon: company.icon,
              websiteUrl: company.websiteUrl,
            })),
          },
          permissions: selectedCompany?.permissions || [],
        },
      });
      setStatus('success');
    } catch (error) {
      logout();
      setStatus('error');
      toastErrorsTranslated(error);
    }
  }, [dispatch, logout]);

  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);

  return { loading: status === 'loading', error: status === 'error' };
};
