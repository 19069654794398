import { AppSidebar } from './sidebar/AppSidebar';
import style from '../../assets/scss/components/Main.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { AppBar } from './header/AppBar';
import useSocketEvents from '../../hooks/useSocketEvents';
import { Socket } from 'socket.io-client';
import React from 'react';
import { useCurrentUserInit } from '../../hooks/user/useCurrentUserInit';
import { AuthenticatedRouter } from '../routers/AuthenticatedRouter';
import { CompanySelectorPage } from '../pages/CompanySelectorPage';
import { useCompanySelector } from '../../hooks/useCompanySelector';

export const WebSocketContext = React.createContext<React.MutableRefObject<Socket | undefined> | null>(null);

export const AuthenticatedLayout: React.FC = () => {
  const { wrapper } = convertKeysToCamelCase(style);
  const { loading, error } = useCurrentUserInit();
  const { socketRef } = useSocketEvents();
  const { selectedCompany } = useCompanySelector();

  if (loading || error) {
    return null;
  }

  if (!selectedCompany) {
    return <CompanySelectorPage />;
  }

  return (
    <WebSocketContext.Provider value={socketRef}>
      <div className="d-flex flex-column mh-100">
        <AppBar />
        <div className={wrapper}>
          <AppSidebar />
          <AuthenticatedRouter />
        </div>
      </div>
    </WebSocketContext.Provider>
  );
};
