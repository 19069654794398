import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import i18n from '../config/i18n';

function isAxiosError(error: AxiosError | unknown): error is AxiosError {
  return (error as AxiosError).response?.data !== undefined;
}

const mapErrors = (error: AxiosError | unknown, transformer?: (arg0: string) => string) => {
  // If error is not axios error then write generic error
  if (!isAxiosError(error)) {
    return ['Generic error'];
  }

  const errorData = error?.response?.data;

  if (!errorData && transformer) {
    return [transformer('error.response.status.general')];
  }

  if (errorData.error === 'Unauthorized' && transformer) {
    return [transformer('error.login.unauthorized')];
  }

  return ['Generic error'];
};

export const toastErrors = (error: AxiosError | unknown, transformer?: (arg0: string) => string) => {
  const errors = mapErrors(error, transformer);
  errors.forEach((error) => {
    toast.error(error);
  });
};

//TODO: Change file name, unify toast functions
//TODO: Figure out standard for errors from backend and map to i18n
export const toastErrorsTranslated = (error: AxiosError | unknown) => toastErrors(error, (arg) => i18n.t(arg));

export const toastErrorTranslated = (error: string) => {
  toast.error(i18n.t(error));
};

export const toastInfoTranslated = (info: string) => {
  toast.info(i18n.t(info));
};

export const toastSuccessTranslated = (msg: string) => {
  toast.success(i18n.t(msg));
};
