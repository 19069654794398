import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import {
  TCreateCannedResponseRequestBody,
  TGetCannedResponsesResponseBody,
  TDeleteCannedReponseRequestBody,
  TCreateCannedResponseResponse,
  TUpdateCannedResponseRequestParam,
  TUpdateCannedResponseRequestBody,
} from './dto/cannedResponse.dto';
import { TGeneralResponse } from './dto/common.dto';

export const getCannedResponsesApi = (): TGeneralResponse<TGetCannedResponsesResponseBody> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.CANNED_RESPONSES);

export const createCannedResponseApi = (
  cannedResponse: TCreateCannedResponseRequestBody,
): TGeneralResponse<TCreateCannedResponseResponse> =>
  axiosInterceptAccessTokenExpiry().post(ENDPOINTS.CANNED_RESPONSES, cannedResponse);

export const deleteCannedResponseApi = (cannedResponseId: TDeleteCannedReponseRequestBody): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.CANNED_RESPONSES}/${cannedResponseId}`);

export const updateCannedResponseApi = (
  cannedResponseId: TUpdateCannedResponseRequestParam,
  cannedResponseData: TUpdateCannedResponseRequestBody,
): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.CANNED_RESPONSES}/${cannedResponseId}`, {
    ...cannedResponseData,
  });
