import { useEffect } from 'react';
import { useMainSelector } from '../useMainSelector';
import { useCannedResponsesActions } from './useCannedResponsesActions';

export const useCannedResponsesEvents = () => {
  const {
    chat: { cannedResponsesMode },
  } = useMainSelector();

  const { getCannedResponses, removeCannedResponses } = useCannedResponsesActions();

  useEffect(() => {
    if (cannedResponsesMode) {
      getCannedResponses();
    } else {
      removeCannedResponses();
    }
  }, [cannedResponsesMode, getCannedResponses, removeCannedResponses]);
};
