import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';

interface IProps {
  title: string;
  name: string;
  type?: string;
  errorMessage?: string;
  registerInput: any;
  autoComplete?: string;
}

export const AuthInput: React.FC<IProps> = ({ title, name, type, registerInput, errorMessage, autoComplete }) => {
  const { t } = useTranslation();

  const { loginFormAuthInput, loginFormAuthInputLabel, formError, emptyError } = convertKeysToCamelCase(style);

  return (
    <div className={loginFormAuthInput}>
      <div className={loginFormAuthInputLabel}>{title}</div>
      <input {...registerInput(name)} type={type} autoComplete={autoComplete} />
      {errorMessage ? (
        <div className={formError}>{t(`validation.${errorMessage}`)}</div>
      ) : (
        <div className={emptyError}></div>
      )}
    </div>
  );
};
