import React from 'react';
import { Button, Modal, Form, Row } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/ModalForm.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { CreateInboxRequestBody } from '../../../../api/dto/inbox.dto';

type Props = {
  inboxData?: Partial<CreateInboxRequestBody>;
  onSubmit: (data: CreateInboxRequestBody) => void;
};

interface IInboxFormData {
  identifier: string;
  name: string;
  skills: string;
}

export const InboxDataModal: React.FC<Props> = ({ inboxData, onSubmit }) => {
  const { inputForm, inputGroup, input, modalSection, modalInput, footer } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm<IInboxFormData>({
    shouldUseNativeValidation: true,
    defaultValues: {
      identifier: inboxData?.identifier,
      name: inboxData?.name,
      skills: inboxData?.skills?.join(', '),
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: IInboxFormData) => {
    onSubmit({
      identifier: data.identifier,
      name: data.name,
      skills: data.skills.split(',').map((inbox) => inbox.trim()),
    });
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <div>
          <div className={`${modalSection} ${modalInput}`}>
            <Form onSubmit={handleSubmit<IInboxFormData>(hideAndSubmit)}>
              <Row className={`${inputGroup}`}>
                <Form.Group className={`${inputForm}`}>
                  <p>Id</p>
                  <Form.Control
                    className={input}
                    {...register('identifier', { required: 'Please enter the inbox ID' })}
                  />
                </Form.Group>
                <Form.Group className={`${inputForm}`}>
                  <p>Name</p>
                  <Form.Control className={input} {...register('name', { required: 'Please enter the inbox name' })} />
                </Form.Group>
                <Form.Group className={`${inputForm}`}>
                  <p>Skills</p>
                  <Form.Control className={input} {...register('skills')} />
                </Form.Group>
              </Row>
              <div className={`${footer}`}>
                <Modal.Footer>
                  <Button type="submit">Save</Button>
                </Modal.Footer>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default InboxDataModal;
