import React, { useEffect, useRef } from 'react';
import styles from '../../../assets/scss/components/chat/chat-content/ChatContent.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useMainSelector } from '../../../hooks/useMainSelector';
import ChatMessageList from './ChatMessageList';
import TypingIndicator from './TypingIndicator';

export const ChatContent: React.FC = () => {
  const { wrapper, typingIndicatorContainer } = convertKeysToCamelCase(styles);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const {
    chat: { currentUserMessages, chatMode, currentUser, typingIndicator },
  } = useMainSelector();

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentUserMessages, chatMode]);

  return (
    <div className={`d-flex flex-column flex-grow-1 ${wrapper}`}>
      {currentUser?.id ? <ChatMessageList /> : null}
      {typingIndicator && (
        <div className={typingIndicatorContainer + ' p-2 m-2'}>
          <TypingIndicator />
        </div>
      )}
      <div id="bottom-scroller" ref={bottomRef} />
    </div>
  );
};
