import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Label } from '../Label';
import { useLabels } from '../../../hooks/settings/labels/useLabels';

export const ChatSidebarLabelDropdown: React.FC = () => {
  const { labelDropdown } = convertKeysToCamelCase(style);
  const { labels } = useLabels();

  return (
    <DropdownButton className={labelDropdown} bsPrefix={labelDropdown} id="dropdown-basic-button" title="Label">
      {labels?.length > 0 &&
        labels.map((label) => {
          return (
            <Dropdown.Item key={label.identifier} href="#/action-1">
              <Label labelData={label} />
            </Dropdown.Item>
          );
        })}
    </DropdownButton>
  );
};

export default ChatSidebarLabelDropdown;
