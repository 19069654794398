import { Actions } from '../../constants/actionTypes';
import { TSettingsPageView } from '../../interfaces/ISettings';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';

export const useSettingsSidebar = () => {
  const { ui } = useMainSelector();
  const dispatch = useMainDispatch();

  const setActiveDestination = (destination?: TSettingsPageView) => {
    destination ? dispatch({ type: Actions.SET_UI, payload: { settingsPageView: destination } }) : null;
  };

  return {
    activeDestination: ui.settingsPageView,
    setActiveDestination,
  };
};
