import React from 'react';
import useChat from '../../../hooks/useChat';
import style from '../../../assets/scss/components/chat/chat-panel-header/ChatPanelHeader.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import InboxDropDown from './InboxDropDown';
import MoreDropDown from './MoreDropDown';
import MoreVertDropDown from './MoreVertDropDown';
import { useHistory } from 'react-router';
import { CHAT_PAGE_ROUTE } from '../../../constants/routes';
import { useMainDispatch } from '../../../hooks/useMainDispatch';
import { Actions } from '../../../constants/actionTypes';
import { useSidebar } from '../../../hooks/useSidebar';

interface Props {
  name: string;
  imageUrl: string;
}

export const ChatPanelHeader: React.FC<Props> = ({ name, imageUrl }) => {
  const history = useHistory();
  const { resolveChat, closeChat } = useChat();
  const { sideBarOpen } = useSidebar();

  const dispatch = useMainDispatch();
  const {
    panelHeader,
    client,
    userAvatar,
    wrapper,
    segmentedControl,
    buttonResolve,
    arrowBack,
    buttonDefault,
    appBarOpen,
  } = convertKeysToCamelCase(style);

  const setChatToResolved = () => {
    history.push(CHAT_PAGE_ROUTE);
    resolveChat();
  };

  const openChatPanelSidebar = () => {
    dispatch({
      type: Actions.SET_UI,
      payload: {
        chatPageView: 'sidebar',
      },
    });
  };

  return (
    <article className={`${sideBarOpen ? appBarOpen : ''} `}>
      <div className={`d-flex justify-content-between ${panelHeader} `}>
        <div className={client}>
          <span className={`material-icons ${arrowBack}`} role="button" onClick={() => closeChat()}>
            arrow_back_ios
          </span>
          <img className={`circle ${userAvatar}`} src={imageUrl} />
          <p onClick={() => openChatPanelSidebar()}>{name}</p>
        </div>

        <div className={wrapper}>
          <InboxDropDown />
          <div className={segmentedControl}>
            <div className={`btn ${buttonDefault}`}>
              <p>Open</p>
            </div>
            <div
              className={`btn ${buttonDefault} ${buttonResolve}`}
              onClick={() => {
                setChatToResolved();
              }}
            >
              <span className={`material-icons`}>check</span>
              <p>Resolve</p>
            </div>
          </div>
          <MoreDropDown />
        </div>
        <MoreVertDropDown />
      </div>
    </article>
  );
};
