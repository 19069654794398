import React from 'react';
import { Dropdown } from 'react-bootstrap';
import style from '../../../assets/scss/components/chat/chat-panel-header/MoreVertDropDown.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';

export const MoreVertDropDown: React.FC = () => {
  const {
    buttonVertMore,
    item,
    title,
    list,
    divider,
    menu,
    iconVertMore,
    dropdown,
    buttonResolve,
    buttonOpen,
    arrowForward,
    inbox,
  } = convertKeysToCamelCase(style);

  return (
    <Dropdown className={buttonVertMore}>
      <Dropdown.Toggle className={dropdown}>
        <span className={`material-icons  ${iconVertMore}`}>more_vert</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className={menu}>
        <Dropdown.Item className={`${item} ${list} ${inbox}`} href="#/action-3">
          <p>Inbox</p>
          <span className={`material-icons ${arrowForward}`} role="button">
            arrow_forward_ios
          </span>
        </Dropdown.Item>
        <Dropdown.Item className={`${item} ${list} ${buttonResolve}`} href="#/action-3">
          <p>Resolve</p>
        </Dropdown.Item>
        <Dropdown.Item className={`${item} ${list} ${buttonOpen}`} href="#/action-3">
          <p>Open</p>
        </Dropdown.Item>
        <Dropdown.Item className={`${item} ${list}`} href="#/action-1">
          <p>Move to trash</p>
        </Dropdown.Item>
        <Dropdown.Item className={`${item} ${list}`} href="#/action-2">
          <p>Mark as spam</p>
        </Dropdown.Item>
        <Dropdown.Divider className={divider} />
        <Dropdown.Item eventKey="4" className={`${item} ${title}`}>
          <p>Block</p>
        </Dropdown.Item>
        <Dropdown.Divider className={divider} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreVertDropDown;
