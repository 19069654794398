import React from 'react';
import { ChatList } from './ChatList';
import { ChatSidebarHeader } from './ChatSidebarHeader';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';

export const ChatSidebar: React.FC = () => {
  const { chatlistWrapper } = convertKeysToCamelCase(style);

  return (
    <div className={chatlistWrapper}>
      <ChatSidebarHeader />
      <ChatList />
    </div>
  );
};
