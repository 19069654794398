import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/chat/chat-panel-sidebar/ChatPanelSidebar.module.scss';
import { TLabel } from '../../interfaces/TChat';
import { useConversationLabelActions } from '../../hooks/chat/useConversationLabelActions';

interface IProps {
  labelData: TLabel;
  removable?: boolean;
}

export const Label: React.FC<IProps> = ({ labelData, removable }) => {
  const { labelColor: color, labelName: text } = labelData;
  const { cvLabel, closeButton } = convertKeysToCamelCase(style);

  const { removeLabelFromConversation } = useConversationLabelActions();

  return (
    <>
      <span
        className={`badge ${cvLabel}`}
        style={{
          backgroundColor: color,
        }}
      >
        {text}
        {removable && (
          <span
            aria-hidden="true"
            className={closeButton}
            onClick={() => removeLabelFromConversation(labelData.identifier)}
          >
            &times;
          </span>
        )}
      </span>
    </>
  );
};
