import { useMainSelector } from '../../useMainSelector';

export const useBlocks = () => {
  const {
    designer: { blocks, newBlock },
  } = useMainSelector();

  return {
    blocks,
    newBlock,
  };
};
