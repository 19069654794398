import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useLabels } from '../../hooks/settings/labels/useLabels';
import { useLabelInit } from '../../hooks/settings/labels/useLabelInit';
import { useLabelActions } from '../../hooks/settings/labels/useLabelActions';

export const LabelSettings: React.FC = () => {
  useLabelInit();
  const { labels } = useLabels();
  const { setSelectedLabel } = useLabelActions();
  const { showModal } = useModal();

  return (
    <>
      {labels?.length > 0 ? (
        <>
          <ContentTitle title={'Labels'} onClickInfo={() => showModal('info-label')} />
          <AddButton text={'Add Label'} onClickAdd={() => showModal('add-label')} />
          <SettingsCardContainer>
            {labels.map((label) => (
              <SettingsCard
                key={label.identifier}
                title={label.labelName}
                label={label.labelColor}
                actions={[
                  {
                    icon: 'border_color',
                    action: () => {
                      setSelectedLabel(label);
                      showModal('edit-label');
                    },
                  },
                  {
                    icon: 'delete',
                    action: () => {
                      setSelectedLabel(label);
                      showModal('delete-label');
                    },
                  },
                ]}
              />
            ))}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState
          message={'It is quite empty around here. Add some labels'}
          addButton={() => showModal('add-label')}
        />
      )}
    </>
  );
};
export default LabelSettings;
