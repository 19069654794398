import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/ModalForm.module.scss';
import { useModal } from '../../../../hooks/modal/useModal';
import { useCannedResponses } from '../../../../hooks/chat/useCannedResponses';
import { useCannedResponsesActions } from '../../../../hooks/chat/useCannedResponsesActions';

export const DeleteCannedResponseModal: React.FC = () => {
  const { modalSection, footer, text } = convertKeysToCamelCase(style);
  const { selectedCannedResponse } = useCannedResponses();
  const { deleteCannedResponse } = useCannedResponsesActions();
  const { hideModal } = useModal();

  return (
    <>
      {selectedCannedResponse && (
        <Modal.Body>
          <div>
            <div className={`${modalSection}`}>
              <p className={`${text}`}>
                Are you sure you want to delete canned response: <b>{selectedCannedResponse.shorthand}</b>?
              </p>
            </div>
          </div>
          <div className={`${footer}`}>
            <Modal.Footer>
              <Button
                onClick={() => {
                  deleteCannedResponse(selectedCannedResponse.identifier);
                  hideModal();
                }}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  hideModal();
                }}
              >
                Abort
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteCannedResponseModal;
