import React from 'react';
import { Modal } from 'react-bootstrap';

export const AgentsInfoModal: React.FC = () => {
  return (
    <Modal.Body>
      <p>
        On this tab you can manage your agents. All your registered agents will appear here, and you can edit, remove or
        create new profiles.
        <br />
        <br />
        <b>Create</b>
        <br />
        To create new agents press the + icon and after filling in the agent properties press the submit button. The
        newly created agent must complete his/her profile at first sign up, including a new password, a profile picture
        ...
        <br />
        <br />
        <b>Edit</b>
        <br />
        To edit an agents profile press the edit button on the card ...
        <br />
        <br />
        <b>Delete</b>
        <br />
        To remove an agent press the delete button on the card ...
      </p>
    </Modal.Body>
  );
};

export default AgentsInfoModal;
