import React from 'react';
import { useHistory } from 'react-router-dom';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useResetPasswordForm } from '../../hooks/auth/useResetPasswordForm';
import { AuthInput } from './AuthInput';
import { HoverText } from '../common/HoverText';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';
import { LOGIN_PAGE_ROUTE } from '../../constants/routes';
import { GenericButton } from '../common/Button';
import { useTranslation } from 'react-i18next';

export const ResetPasswordForm: React.FC = () => {
  const { submitResetPassword, registerInput, validationErrors } = useResetPasswordForm();

  const history = useHistory();
  const switchToLogin = () => history.push(LOGIN_PAGE_ROUTE);

  const { t } = useTranslation();

  const { loginForm, loginFormFrame, loginFormFrameButtonsOptionsForgot, resetInstructions } =
    convertKeysToCamelCase(style);

  return (
    <>
      <form className={loginForm} onSubmit={submitResetPassword}>
        <p className={resetInstructions}>{t('auth.reset-password.instructions')}</p>

        <AuthInput
          title="password"
          name="password"
          type="password"
          errorMessage={validationErrors?.password?.message}
          registerInput={registerInput}
        />

        <AuthInput
          title="password check"
          name="passwordCheck"
          type="password"
          errorMessage={validationErrors?.passwordCheck?.message}
          registerInput={registerInput}
        />

        <div className={loginFormFrame}>
          <GenericButton buttonType="primary" text={t('auth.reset-password.submit')} type="submit" />
        </div>
      </form>
      <HoverText className={loginFormFrameButtonsOptionsForgot} text="Back to login" onClick={switchToLogin} />
    </>
  );
};
