import React from 'react';

interface Props {
  title: string;
  children: any;
}

export const WebclientInputGroup: React.FC<Props> = ({ children, title }) => {
  return (
    <div className="my-3 p-2 border bg-light">
      <b>{title}</b>
      {children}
    </div>
  );
};

export default WebclientInputGroup;
