import React from 'react';
import style from '../../assets/scss/components/pages/MainPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import SettingsContent from '../settings-page/SettingsContent';
import { SettingsSidebar } from '../settings-page/sidebar/SettingsSidebar';

export const SettingsPage: React.FC = () => {
  const { wrapper } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <SettingsSidebar />
      <SettingsContent />
    </div>
  );
};
