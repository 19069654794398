import { PERMISSIONS } from '../../constants/permissions';
import { CHAT_PAGE_ROUTE, REPORTS_PAGE_ROUTE, DASHBOARD_PAGE_ROUTE, DESIGNER_PAGE_ROUTE } from '../../constants/routes';
import { ISideBarRouting } from '../../interfaces/ISidebar';

export const SidebarRoutes: ISideBarRouting[] = [
  {
    text: 'Conversations',
    permissions: [PERMISSIONS.CHAT_MANAGEMENT],
    icon: 'chat',
    to: CHAT_PAGE_ROUTE,
  },
  {
    text: 'Reports',
    permissions: [PERMISSIONS.REPORTING_VIEW],
    icon: 'assessment',
    to: REPORTS_PAGE_ROUTE,
  },
  {
    text: 'Dashboard',
    to: DASHBOARD_PAGE_ROUTE,
    icon: 'space_dashboard',
  },
  {
    text: 'Designer',
    permissions: [PERMISSIONS.BLOCK_MANAGE],
    icon: 'architecture',
    to: DESIGNER_PAGE_ROUTE,
  },
];
