import React from 'react';
import { Modal } from 'react-bootstrap';

export const InboxesInfoModal: React.FC = () => {
  return (
    <Modal.Body>
      <p>
        On this tab you can manage your inboxes. All your registered inboxes will appear here, and you can edit, remove
        or create new ones.
        <br />
        <br />
        <b>Create</b>
        <br />
        To create new inbox press the + icon and after filling in the inbox properties press the submit button. ...
        <br />
        <br />
        <b>Edit</b>
        <br />
        To edit an inbox press the edit button on the card ...
        <br />
        <br />
        <b>Delete</b>
        <br />
        To remove an inbox press the delete button on the card ...
      </p>
    </Modal.Body>
  );
};

export default InboxesInfoModal;
