import style from '../../../assets/scss/components/header/AppBar.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import ProfileDropdown from './dropdown/ProfileDropdown';
import { useAppBar } from '../../../hooks/useAppBar';
import chatAvatar from '../../../assets/images/chat_avatar.png';

export const Profile = () => {
  const { loggedInUser, profileDropdownOpen, toggleProfileDropdown } = useAppBar();

  const { userNameType, cvUser, userAvatar } = convertKeysToCamelCase(style);

  return (
    <div className="navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          <div className={cvUser}>
            <img onClick={() => toggleProfileDropdown()} src={chatAvatar} className={userAvatar} />
            <a
              onClick={() => toggleProfileDropdown()}
              className="nav-link"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className={userNameType}>
                {loggedInUser?.firstName}
                <span>Administrator</span>
              </div>
            </a>
            {profileDropdownOpen && <ProfileDropdown />}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Profile;
