import React from 'react';
import { useTranslation } from 'react-i18next';
import style from '../../assets/scss/components/pages/CompanySelectorPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import conversedLogo from '../../assets/images/logo_black.png';
import { useCompanySelector } from '../../hooks/useCompanySelector';
import CompanyCard from '../auth/CompanyCard';

export const CompanySelectorPage: React.FC = () => {
  const { wrapper, content, copyright, title, cardContainer, contentWrapper } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const { companies, setSelectedCompany } = useCompanySelector();

  return (
    <div className={`${wrapper} `}>
      <div>
        <div className={`w-100 d-flex justify-content-center align-items-center ${contentWrapper}`}>
          <div className={`w-100 h-100 d-flex justify-content-center flex-column align-items-center ${content}`}>
            <img src={conversedLogo} />
            <div className={`${title} mx-3`}>Choose/add customer</div>
            <div className={`w-100 ${cardContainer} `}>
              {companies?.map((company) => (
                <CompanyCard
                  key={company.identifier}
                  name={company.name}
                  iconUrl={company.icon}
                  url={company.websiteUrl}
                  onPress={() => setSelectedCompany(company)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={copyright}>{t('general.copyright.label')}</div>
    </div>
  );
};
