import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-panel-sidebar/ChatPanelSidebar.module.scss';

export const AddLabelButton: React.FC = () => {
  const { cvAddLabel } = convertKeysToCamelCase(style);

  // TODO: Add container for lables
  return (
    <>
      <span className={`badge ${cvAddLabel}`}>{'+'}</span>
    </>
  );
};
