import React from 'react';
import { Form } from 'react-bootstrap';
import { IInputProps } from '../../../../../interfaces/IInputProps';

export const WebclientBooleanInput: React.FC<IInputProps> = ({ label, name, register }) => {
  return (
    <Form.Group className="d-flex flex-col align-items-center">
      <p className="me-2">{label}:</p>
      <Form.Check {...register(name)} />
    </Form.Group>
  );
};

export default WebclientBooleanInput;
