import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';
import { Config, GetWebclientResponseDto } from './dto/webclient.dto';

export const getWebclientConfigsEndpoint = (): TGeneralResponse<GetWebclientResponseDto> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.WEBCLIENT}/configs`);

export const createWebclientConfigEndpoint = (name: string, config: Config): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.WEBCLIENT}/configs`, { name, config });

export const updateWebclientConfigEndpoint = (identifier: string, name: string, config: Config): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.WEBCLIENT}/configs/${identifier}`, { name, config });

export const deleteWebclientConfigEndpoint = (identifier: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.WEBCLIENT}/configs/${identifier}`);

export const addWebclientWhitelistEndpoint = (domain: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.WEBCLIENT}/whitelist`, { domain });

export const removeWebclientWhitelistedDomainEndpoint = (domain: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.WEBCLIENT}/whitelist`, { params: { domain } });
