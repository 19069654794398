import { DataKeys } from '../constants/login';
import { EFlowDirection } from '../interfaces/EFlowDirection';
import { TLoginInner } from '../interfaces/redux/TLoginState';
import { TReduxState } from '../interfaces/TReduxState';

// TODO make abstraction for local storage
const getInitialAccessToken = () => localStorage.getItem(DataKeys.ACCESS_TOKEN_DATA_KEY) || undefined;
const getInitialRefreshToken = () => localStorage.getItem(DataKeys.REFRESH_TOKEN_DATA_KEY) || undefined;
const getInitialLoggedIn = () => !!getInitialAccessToken() && !!getInitialRefreshToken();

const getInitialLoginCredentials = (): TLoginInner => ({
  isLoggedIn: getInitialLoggedIn(),
  refreshToken: getInitialRefreshToken(),
  accessToken: getInitialAccessToken(),
});

export const userDefaults = {
  currentUser: undefined,
  email: '',
  password: '',
  permissions: undefined,
};

export const initialState: TReduxState = {
  user: userDefaults,
  ui: {
    chatPageView: 'list',
    settingsPageView: 'agents',
    profileDropdownOpen: false,
    sideBarOpen: true,
    isLoading: false,
    activeModal: undefined,
  },
  login: getInitialLoginCredentials(),
  chat: {
    selectedChatList: 'assigned-to-me',
    searchString: '',
    currentUser: undefined,
    currentUserMessages: [],
    typingIndicator: false,
    chatMode: 'reply',
    chatInputMessage: '',
    cannedResponses: [],
    cannedResponsesIndex: 0,
    cannedResponsesMode: false,
    chatList: [],
    inboxes: undefined,
    selectedInbox: undefined,
  },
  settings: {
    agents: [],
    selectedAgent: undefined,
    inboxes: [],
    selectedInbox: undefined,
    selectedCannedResponse: undefined,
    labels: [],
    selectedLabel: undefined,
    webclient: { configs: [] },
  },
  designer: {
    selectedBlock: undefined,
    blocksSearchString: '',
    blocksSearchMatchIndex: 0,
    flowDirection: EFlowDirection.Vertical,
    newBlock: undefined,
    blocks: [],
  },
  colors: {
    default: '#aaaaaa',
  },
};
