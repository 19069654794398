import { formatLoginPayload, formatRefreshTokenPayload } from '../helpers/apiHelper';
import { IForgotPasswordPayload } from '../interfaces/payloads/IForgotPasswordPayload';
import { IUserLoginPayload } from '../interfaces/ILogin';
import { IPasswordResetPayload } from '../interfaces/payloads/IPasswordResetPayload';
import { axiosDefault } from './axios/axios';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { ICheckResetPasswordPayload } from '../interfaces/payloads/ICheckResetPasswordPayload';
import { ENDPOINTS } from '../constants/apiEndpoints';
import { TDTOGetCurrentUserResposne } from './dto/user.dto';
import { TGeneralResponse } from './dto/common.dto';

export const loginApi = (payload: IUserLoginPayload): TGeneralResponse<any> =>
  axiosDefault().post(ENDPOINTS.OAUTH_LOGIN, formatLoginPayload(payload), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const refreshAccessTokenApi = (token: string): TGeneralResponse<any> =>
  axiosDefault().post(ENDPOINTS.OAUTH_LOGIN, formatRefreshTokenPayload(token), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const sendPasswordResetEmailApi = (payload: IForgotPasswordPayload): TGeneralResponse<any> =>
  axiosDefault().post(ENDPOINTS.FORGOT_PASSWORD, payload, {});

export const checkResetCodeApi = (payload: ICheckResetPasswordPayload): TGeneralResponse<any> =>
  axiosDefault().post(ENDPOINTS.CHECK_RESET_PASSWORD_TOKEN, payload, {});

export const resetPasswordApi = (payload: IPasswordResetPayload): TGeneralResponse<any> =>
  axiosDefault().post(ENDPOINTS.RESET_PASSWORD, payload, {});

export const meApi = (): TGeneralResponse<TDTOGetCurrentUserResposne> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.USER_DATA);

export const getPermissionsApi = (): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.USER_ROUTE);
