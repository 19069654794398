import React, { useState } from 'react';
import { ChatPanel } from '../chat/chat-panel/ChatPanel';
import { ChatSidebar } from '../chat/chat-sidebar/ChatSidebar';
import { useChatEvents } from '../../hooks/useChatEvents';
import { useMainSelector } from '../../hooks/useMainSelector';
import { ChatPanelSidebar } from '../chat/chat-panel/ChatPanelSidebar';
import { TChatPageView, TChatUser } from '../../interfaces/TChat';
import { useMedia } from '../../hooks/useMedia';
import { EBreakpoint } from '../../constants/breakpoints';
import { ChatPanelHeader } from '../chat/chat-panel-header/ChatPanelHeader';
import chatAvatar from '../../assets/images/chat_avatar.png';
import { getImageByURLHelper } from '../../helpers/dataHelpers';
import { useLabelInit } from '../../hooks/settings/labels/useLabelInit';

const renderMobileSwitch = (element: TChatPageView, toggleSidebar: () => void, currentUser?: TChatUser) => {
  switch (element) {
    case 'list':
      return <ChatSidebar />;
    case 'conversations':
      return (
        <>
          {currentUser && (
            <ChatPanelHeader
              name={currentUser.name}
              imageUrl={getImageByURLHelper(currentUser.imageUrl) ?? chatAvatar}
            />
          )}
          <ChatPanel sidebarState={false} toggleSidebar={toggleSidebar} />;
        </>
      );
    case 'sidebar':
      return currentUser && <ChatPanelSidebar currentUser={currentUser} />;
    default:
      return null;
  }
};

export const ChatPage: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const {
    ui: { chatPageView },
    chat: { currentUser },
  } = useMainSelector();
  const { down: mediaDown } = useMedia(EBreakpoint.MD);

  useChatEvents();
  useLabelInit();

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <div className="d-flex flex-row flex-grow-1">
      {mediaDown ? (
        renderMobileSwitch(chatPageView, toggleSidebar, currentUser)
      ) : (
        <>
          <ChatSidebar />
          <div className="d-flex flex-column flex-grow-1">
            {currentUser && (
              <ChatPanelHeader
                name={currentUser.name}
                imageUrl={getImageByURLHelper(currentUser.imageUrl) ?? chatAvatar}
              />
            )}

            <div className="d-flex flex-row flex-grow-1">
              <ChatPanel sidebarState={sidebarOpen} toggleSidebar={toggleSidebar} />
              {currentUser && sidebarOpen && <ChatPanelSidebar currentUser={currentUser} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
