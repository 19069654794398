export const MAIN_PAGE_ROUTE = '/';
export const LOGIN_PAGE_ROUTE = '/login';
export const LOGOUT_PAGE_ROUTE = '/logout';
export const PASSWORD_RESET_ROUTE = '/login/recovery/:code';
export const OTP_PAGE_ROUTE = '/login/step';
export const FORGOT_PASSWORD_ROUTE = '/login/forgot';
export const CHAT_PAGE_ROUTE = '/chat';
export const REPORTS_PAGE_ROUTE = '/reports';
export const DASHBOARD_PAGE_ROUTE = '/dashboard';
export const DESIGNER_PAGE_ROUTE = '/designer';
export const SETTINGS_PAGE_ROUTE = '/settings';
