import { Actions } from '../../constants/actionTypes';
import { useCallback, useMemo } from 'react';
import { useMainSelector } from '../useMainSelector';
import { useMainDispatch } from '../useMainDispatch';
import { useChatInput } from './useChatInput';
import { TCannedResponse } from '../../interfaces/TChat';

export const useCannedResponses = () => {
  const {
    chat: { cannedResponses, chatInputMessage, cannedResponsesMode, cannedResponsesIndex },
    settings: { selectedCannedResponse },
  } = useMainSelector();

  const { setMessage } = useChatInput();
  const dispatch = useMainDispatch();

  const filteredCannedResponses = useMemo(
    () => cannedResponses?.filter(({ shorthand }) => shorthand.toLowerCase().includes(chatInputMessage.toLowerCase())),
    [cannedResponses, chatInputMessage],
  );

  const setCannedResponsesMode = useCallback(
    (value: boolean) => {
      dispatch({ type: Actions.SET_CHAT, payload: { cannedResponsesMode: value } });
    },
    [dispatch],
  );

  const setCurrentResponse = useCallback(
    (response: TCannedResponse['message']) => {
      setMessage(response);
      dispatch({ type: Actions.SET_CHAT, payload: { cannedResponsesMode: false } });
    },
    [dispatch, setMessage],
  );

  const toggleCannedResponsesMode = useCallback(() => {
    setMessage('');
    setCannedResponsesMode(!cannedResponsesMode);
  }, [cannedResponsesMode, setCannedResponsesMode, setMessage]);

  const setSelectedCannedResponse = useCallback(
    (cannedResponse: TCannedResponse) => {
      dispatch({ type: Actions.SET_SETTINGS, payload: { selectedCannedResponse: cannedResponse } });
    },
    [dispatch],
  );

  return {
    cannedResponsesIndex,
    filteredCannedResponses,
    cannedResponses,
    setCurrentResponse,
    setCannedResponsesMode,
    cannedResponsesMode,
    toggleCannedResponsesMode,
    setSelectedCannedResponse,
    selectedCannedResponse,
  };
};
