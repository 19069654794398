import React from 'react';
import style from '../../assets/scss/components/pages/MainPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { Greeting } from '../common/Greeting';

export const NotFoundPage: React.FC = () => {
  const { wrapper, greetingText } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <Greeting className={greetingText} text={`404 Page not found`} />
    </div>
  );
};
