import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { refreshAccessTokenApi } from '../api/userApi';
import { DataKeys } from '../constants/login';
import { TAxiosErrorHandler, TAxiosInterceptor, TAxiosResponseHandler } from '../interfaces/IAxios';

type InterceptedRequest = (token: string) => void;

let interceptedRequests: Array<InterceptedRequest> = [];
let isRefreshing = false;

/**
 * In case we get the token expiry from the server, send the refresh_token to the server and then re-send the failed request
 * @returns The interceptor to handle the case
 */
export const getTokenRefreshInterceptor = (interceptedRequestInstance: AxiosInstance): TAxiosInterceptor => {
  const onAccessTokenRefreshed = (token: string) => {
    interceptedRequests.forEach((request) => request(token));
  };

  const subscribeRequest = (cb: InterceptedRequest) => {
    interceptedRequests.push(cb);
  };

  // * On response do nothing
  const response: TAxiosResponseHandler = (response: AxiosResponse) => {
    return response;
  };

  const error: TAxiosErrorHandler = (error: AxiosError) => {
    const { config: originalRequestConfig, response } = error;
    const statusCode = response?.status;

    if (statusCode !== 401) {
      return Promise.reject(error);
    }

    const refreshToken = localStorage.getItem(DataKeys.REFRESH_TOKEN_DATA_KEY);

    if (typeof refreshToken === 'string') {
      if (!isRefreshing) {
        isRefreshing = true;
        return refreshAccessTokenApi(refreshToken)
          .then((response) => {
            const { data } = response;
            const { refreshToken, accessToken } = data;
            if (refreshToken) {
              localStorage.setItem(DataKeys.REFRESH_TOKEN_DATA_KEY, refreshToken);
            }
            if (accessToken) {
              localStorage.setItem(DataKeys.ACCESS_TOKEN_DATA_KEY, accessToken);
              originalRequestConfig.headers.Authorization = `Bearer ${accessToken}`;
              onAccessTokenRefreshed(accessToken);
            }

            return interceptedRequestInstance.request({ ...originalRequestConfig });
          })
          .catch((error) => {
            localStorage.removeItem(DataKeys.ACCESS_TOKEN_DATA_KEY);
            localStorage.removeItem(DataKeys.REFRESH_TOKEN_DATA_KEY);
            return Promise.reject(error);
          })
          .finally(() => {
            interceptedRequests = [];
            isRefreshing = false;
          });
      }

      return new Promise((resolve) => {
        subscribeRequest((token: string) => {
          originalRequestConfig.headers.Authorization = `Bearer ${token}`;
          resolve(interceptedRequestInstance(originalRequestConfig));
        });
      });
    }
    return Promise.reject(error);
  };

  return { response, error };
};
