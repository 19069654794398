import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useSignInForm } from '../../hooks/auth/useSignInForm';
import { AuthInput } from './AuthInput';
import { HoverText } from '../common/HoverText';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';
import { GenericButton } from '../common/Button';
import { useHistory } from 'react-router-dom';
import { FORGOT_PASSWORD_ROUTE } from '../../constants/routes';

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { submitSignIn, registerInput, validationErrors } = useSignInForm();
  const history = useHistory();

  const switchToForgotPassword = () => history.push(FORGOT_PASSWORD_ROUTE);

  const switchToSignUp = () => {
    // TODO implement
  };

  const {
    loginForm,
    loginFormFrame,
    loginFormFrameSignup,
    loginFormFrameButtonsOptions,
    loginFormFrameButtonsOptionsForgot,
  } = convertKeysToCamelCase(style);

  return (
    <form className={loginForm} onSubmit={submitSignIn}>
      <AuthInput
        title="email"
        name="email"
        autoComplete="username"
        errorMessage={validationErrors?.email?.message}
        registerInput={registerInput}
      />
      <AuthInput
        title="password"
        name="password"
        type="password"
        autoComplete="current-password"
        errorMessage={validationErrors?.password?.message}
        registerInput={registerInput}
      />

      <div className={loginFormFrameButtonsOptions}>
        <div className="conversed-checkbox">
          <input type="checkbox" id="remember-me-cbox" name="remember-me-cbox" />
          <label htmlFor="remember-me-cbox">
            <span className="icon material-icons">check</span>
            <p>Remember me</p>
          </label>
        </div>
        <HoverText
          className={loginFormFrameButtonsOptionsForgot}
          text="Forgot password?"
          onClick={switchToForgotPassword}
        />
      </div>

      <div className={loginFormFrame}>
        <GenericButton buttonType="primary" text={t(`general.buttons.login`)} type="submit" />
        <HoverText className={loginFormFrameSignup} text="Don’t have an account? Sign up!" onClick={switchToSignUp} />
      </div>
    </form>
  );
};
