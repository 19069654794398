import React from 'react';
import { ChatCard } from './ChatCard';
import { useChatList } from '../../../hooks/chat/useChatList';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';

export const ChatList: React.FC = () => {
  const { chatlistMessageWrapper } = convertKeysToCamelCase(style);

  const { chatList, selectedChatUser } = useChatList();

  return (
    <div className={chatlistMessageWrapper}>
      {chatList.map((chatUser) => (
        <div key={chatUser.id} className="mt-1">
          <ChatCard chatUser={chatUser} isSelected={chatUser?.id === selectedChatUser?.id} />
        </div>
      ))}
    </div>
  );
};
