import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import style from '../../assets/scss/components/pages/AuthPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { AuthComponent } from '../auth/AuthComponent';
import { LOGIN_PAGE_ROUTE, OTP_PAGE_ROUTE, PASSWORD_RESET_ROUTE, FORGOT_PASSWORD_ROUTE } from '../../constants/routes';
import { TAuthState } from '../../interfaces/TAuthState';

export const AuthPage: React.FC = () => {
  const { wrapper, auth, copyright, background } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const authStepMap: Record<TAuthState, boolean> = {
    'forgot-password': !!useRouteMatch(FORGOT_PASSWORD_ROUTE),
    'password-reset': !!useRouteMatch(PASSWORD_RESET_ROUTE),
    otp: !!useRouteMatch(OTP_PAGE_ROUTE),
    login: !!useRouteMatch(LOGIN_PAGE_ROUTE),
  };

  const authStep = Object.entries(authStepMap).find((step) => step[1])?.[0] || 'login';

  return (
    <div className={`${wrapper} container-fluid`}>
      <div className="row">
        <div className={`${auth} col-md-4 col-sm-12`}>
          <AuthComponent authState={authStep as TAuthState} />
          <div className={copyright}>{t('general.copyright.label')}</div>
        </div>
        <div className={`${background} col-md-8`}></div>
      </div>
    </div>
  );
};
