import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';

import { Dropdown, DropdownButton } from 'react-bootstrap';

export const ChatSidebarStatusDropdown: React.FC = () => {
  const { statusDropdown, active } = convertKeysToCamelCase(style);

  return (
    <DropdownButton className={statusDropdown} bsPrefix={statusDropdown} id="dropdown-basic-button" title="Open">
      <Dropdown.Item className={active} href="#/action-1">
        Opened
      </Dropdown.Item>
      <Dropdown.Item href="#/action-1">Resolved</Dropdown.Item>
    </DropdownButton>
  );
};

export default ChatSidebarStatusDropdown;
