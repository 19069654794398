import React from 'react';
import { Navbar } from 'react-bootstrap';
import { useSidebar } from '../../../hooks/useSidebar';

// Icons
import appBarIcon from '../../../assets/images/logo-blue.svg';

import style from '../../../assets/scss/components/header/AppBar.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { Link } from 'react-router-dom';
import { Profile } from './Profile';

import { MaterialIcon } from '../../common/MaterialIcon';

export const AppBar: React.FC = () => {
  const { toggleSideBar } = useSidebar();
  const { appBar, cvNavWrapper, cvNavBreak, menuLogoWrapper, cvLogo, cvMenu } = convertKeysToCamelCase(style);

  return (
    <div className={appBar}>
      <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-space-between flex-nowrap">
        <div className="container-fluid">
          <div className={menuLogoWrapper}>
            <div className={cvMenu} role="button" onClick={() => toggleSideBar()}>
              <MaterialIcon icon={'menu'} type={'rounded'} />
            </div>
            <Navbar as={Link} to="/">
              <img src={appBarIcon} className={cvLogo} />
            </Navbar>
          </div>
          <div className={cvNavWrapper}>
            <div>
              <MaterialIcon icon={'notifications'} />
            </div>
            <div className={cvNavBreak}></div>
            <Profile />
          </div>
        </div>
      </nav>
    </div>
  );
};
