import { useCallback } from 'react';
import { useCannedResponses } from './useCannedResponses';
import { useCannedResponsesMenu } from './useCannedResponsesMenu';
import { useChatInput } from './useChatInput';
import { useChatTyping } from './useChatTyping';

type TKeyboardPressTextArea = React.KeyboardEvent<HTMLTextAreaElement>;
type TChangeTextArea = React.ChangeEvent<HTMLTextAreaElement>;

export const useChatInputEvents = () => {
  const { chatMode, message, setMessage, sendMessage } = useChatInput();
  const { setTyping } = useChatTyping();
  const { cannedResponsesMode, toggleCannedResponsesMode } = useCannedResponses();
  const { increaseCannedResponseIndex, decreaseCannedResponseIndex, setCurrentResponseBasedOnIndex } =
    useCannedResponsesMenu();

  const handleInputChange = useCallback(
    (event: TChangeTextArea) => {
      const {
        target: { value },
      } = event;

      setMessage(value);
      setTyping();
    },
    [setMessage, setTyping],
  );

  const handleTextAreaKeyDown = useCallback(
    (event: TKeyboardPressTextArea) => {
      // Keydown events
      const handleCannedResponseUpEvent = (event: TKeyboardPressTextArea) => {
        const { code } = event;

        if (code === 'ArrowUp') {
          decreaseCannedResponseIndex();
          event.preventDefault();
        }
      };

      const handleCannedResponseDownEvent = (event: TKeyboardPressTextArea) => {
        const { code } = event;

        if (code === 'ArrowDown') {
          increaseCannedResponseIndex();
          event.preventDefault();
        }
      };

      const handleCannedResponseEnterEvent = (event: TKeyboardPressTextArea) => {
        const { code } = event;

        if (code === 'Enter' || code === 'NumpadEnter') {
          setCurrentResponseBasedOnIndex();
          event.preventDefault();
        }
      };

      const handleCannedResponseBackspaceEvent = (event: TKeyboardPressTextArea) => {
        const { code } = event;

        if (code === 'Backspace') {
          toggleCannedResponsesMode();
          event.preventDefault();
        }
      };

      const handleCannedResponseSlashEvent = (event: TKeyboardPressTextArea) => {
        const { code } = event;

        if (message === '' && chatMode === 'reply' && code === 'Slash') {
          toggleCannedResponsesMode();
          event.preventDefault();
        }
      };

      const { code } = event;

      // Switch in and out of canned response mode via slash
      handleCannedResponseSlashEvent(event);

      if (cannedResponsesMode) {
        handleCannedResponseUpEvent(event);
        handleCannedResponseDownEvent(event);
        handleCannedResponseEnterEvent(event);
        handleCannedResponseBackspaceEvent(event);
      } else {
        // On enter send the message
        if (code === 'Enter' || code === 'NumpadEnter') {
          sendMessage();
          setMessage('');
          event.preventDefault();
        }
      }
    },
    [
      cannedResponsesMode,
      chatMode,
      decreaseCannedResponseIndex,
      increaseCannedResponseIndex,
      message,
      sendMessage,
      setCurrentResponseBasedOnIndex,
      setMessage,
      toggleCannedResponsesMode,
    ],
  );

  return {
    handleInputChange: handleInputChange,
    handleInputKeydown: handleTextAreaKeyDown,
  };
};
