import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/CustomControls.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { useFlowControls } from '../../../hooks/flow/useFlowControls';
import { GeneralTooltip } from '../../common/GeneralTooltip';
import { EFlowDirection } from '../../../interfaces/EFlowDirection';

const getComponentPropertiesByFlowDirection = (flowDirection: EFlowDirection) => {
  switch (flowDirection) {
    case EFlowDirection.Vertical: {
      return {
        text: 'Flip layout horizontally',
        icon: 'vertical_distribute',
      };
    }
    case EFlowDirection.Horizontal: {
      return {
        text: 'Flip layout vertically',
        icon: 'horizontal_distribute',
      };
    }
  }
};

export const FlipLayoutButton: React.FC = () => {
  const { flipFlowLayout, flowDirection } = useFlowControls();
  const { button } = convertKeysToCamelCase(style);
  const { icon, text } = getComponentPropertiesByFlowDirection(flowDirection);

  return (
    <GeneralTooltip id={'tooltip-flow-layout-flip-button'} text={text}>
      <div className={button} onClick={() => flipFlowLayout()}>
        <MaterialIcon icon={icon} />
      </div>
    </GeneralTooltip>
  );
};
