import { useMemo } from 'react';
import { IMessageGroup, TChatHistory } from '../../interfaces/IMessage';
import { useMainSelector } from '../useMainSelector';

export const useChatMessages = () => {
  const {
    chat: { currentUserMessages, chatMode },
  } = useMainSelector();

  const filteredUserMessages = useMemo(
    () => currentUserMessages.filter(({ type }) => type === 'note'),
    [currentUserMessages],
  );

  const messages = useMemo(
    () => (chatMode === 'reply' ? currentUserMessages : filteredUserMessages),
    [chatMode, currentUserMessages, filteredUserMessages],
  );

  const messageGroups = useMemo(
    () =>
      messages.reduce((messageGroups: IMessageGroup[], current: TChatHistory) => {
        const lastGroup = messageGroups[messageGroups.length - 1];
        if (lastGroup?.direction !== current.direction) {
          messageGroups.push({
            messages: [current],
            direction: current.direction,
            key: new Date(current.time).getTime().toString(),
          });
        } else {
          lastGroup.messages.push(current);
        }
        return messageGroups;
      }, []),
    [messages],
  );

  return {
    messageGroups,
  };
};
