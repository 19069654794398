import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../../helpers/baseHelpers';
import { useModal } from '../../../../../hooks/modal/useModal';
import { useWebclient } from '../../../../../hooks/settings/webclients/useWebclient';
import { useWebclientActions } from '../../../../../hooks/settings/webclients/useWebclientActions';
import style from '../../../../../assets/scss/components/settings-page/ModalForm.module.scss';

export const RemoveWebclientWhitelistModal: React.FC = () => {
  const { modalSection, footer, text } = convertKeysToCamelCase(style);
  const { webclient } = useWebclient();
  const { removeWebclientWhitelistedDomain } = useWebclientActions();
  const { hideModal } = useModal();

  return (
    <>
      {webclient?.selectedWhitelistedDomain && (
        <Modal.Body>
          <div>
            <div className={`${modalSection}`}>
              <p className={`${text}`}>
                Are you sure you want to remove <b>{webclient.selectedWhitelistedDomain}</b> from the list of
                whitelisted domains?
              </p>
            </div>
          </div>
          <div className={`${footer}`}>
            <Modal.Footer>
              <Button
                onClick={() => {
                  if (webclient.selectedWhitelistedDomain) {
                    removeWebclientWhitelistedDomain(webclient.selectedWhitelistedDomain);
                    hideModal();
                  }
                }}
              >
                Remove
              </Button>
              <Button
                onClick={() => {
                  hideModal();
                }}
              >
                Abort
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      )}
    </>
  );
};

export default RemoveWebclientWhitelistModal;
