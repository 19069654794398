import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { SpinnerComponent } from 'react-element-spinner';

import { AuthenticatedLayout } from './layouts/AuthenticatedLayout';
import { UnauthenticatedLayout } from './layouts/UnauthenticatedLayout';
import { useMainSelector } from '../hooks/useMainSelector';
import { ModalContainer } from './layouts/modal/ModalContainer';

export const Main: React.FC = () => {
  const {
    login: { isLoggedIn },
    ui: { isLoading },
  } = useMainSelector();

  return (
    <>
      <SpinnerComponent loading={isLoading} position="global" message="Loading" />
      <Router>{isLoggedIn ? <AuthenticatedLayout /> : <UnauthenticatedLayout />}</Router>
      <ToastContainer hideProgressBar={true} transition={Slide} autoClose={3000} />
      <ModalContainer />
    </>
  );
};
