import { Modal, Form, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { convertKeysToCamelCase } from '../../../../../helpers/baseHelpers';
import { useModal } from '../../../../../hooks/modal/useModal';
import { useWebclientActions } from '../../../../../hooks/settings/webclients/useWebclientActions';
import style from '../../../../../assets/scss/components/settings-page/ModalForm.module.scss';
import { URL_REGEX } from '../../../../../constants/regex';

export const AddWebclientWhitelistModal: React.FC = () => {
  const { addWhitelistedDomain } = useWebclientActions();

  const { inputForm, inputGroup, input, modalSection, modalInput, footer } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm<{ domain: string }>({
    shouldUseNativeValidation: true,
    defaultValues: {
      domain: '',
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = ({ domain }: { domain: string }) => {
    addWhitelistedDomain(domain);
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <div>
          <div className={`${modalSection} ${modalInput}`}>
            <Form onSubmit={handleSubmit<{ domain: string }>(hideAndSubmit)}>
              <Row className={`${inputGroup}`}>
                <Form.Group className={`${inputForm}`}>
                  <p>Domain</p>
                  <Form.Control
                    placeholder="https://conversed.ai"
                    className={input}
                    {...register('domain', {
                      required: 'Please enter the domain',
                      pattern: { value: URL_REGEX, message: 'Wrong URL format' },
                    })}
                  />
                </Form.Group>
              </Row>
              <div className={`${footer}`}>
                <Modal.Footer>
                  <Button type="submit">Add</Button>
                </Modal.Footer>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AddWebclientWhitelistModal;
