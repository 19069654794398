import React from 'react';
import { Form } from 'react-bootstrap';
import { IDropdownInputProps } from '../../../../../interfaces/IInputProps';

export const WebclientDropDownInput: React.FC<IDropdownInputProps> = ({ name, register, values, label }) => {
  return (
    <Form.Group>
      <p>{label}</p>
      <Form.Select {...register(name)}>
        {values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default WebclientDropDownInput;
