import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useInboxes } from '../../hooks/settings/inboxes/useInboxes';
import { useInboxesInit } from '../../hooks/settings/inboxes/useInboxesInit';

export const InboxSettings: React.FC = () => {
  useInboxesInit();
  const { inboxes, setSelectedInbox } = useInboxes();
  const { showModal } = useModal();

  return (
    <>
      {inboxes?.length ? (
        <>
          <ContentTitle title={'Inboxes'} onClickInfo={() => showModal('inbox-info')} />
          <AddButton text={'Add inbox'} onClickAdd={() => showModal('add-inbox')} />
          <SettingsCardContainer>
            {inboxes.map((inbox) => (
              <SettingsCard
                key={inbox.identifier}
                title={inbox.name}
                subtitle={inbox.skills.join(', ')}
                actions={[
                  {
                    icon: 'border_color',
                    action: () => {
                      showModal('edit-inbox');
                      setSelectedInbox(inbox);
                    },
                  },
                  {
                    icon: 'delete',
                    action: () => {
                      showModal('delete-inbox');
                      setSelectedInbox(inbox);
                    },
                  },
                ]}
              />
            ))}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState
          message={'It is quite empty around here. Add some inboxes'}
          addButton={() => showModal('add-inbox')}
        />
      )}
    </>
  );
};
export default InboxSettings;
