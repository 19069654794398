import { useCallback, useMemo } from 'react';
import { assignInboxToConversation } from '../../api/chatApi';
import { Actions } from '../../constants/actionTypes';
import { toastErrorsTranslated } from '../../helpers/toastErrors';
import { TChatInbox } from '../../interfaces/TChat';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';

export const useChatInboxes = () => {
  const {
    chat: { inboxes, selectedInbox, currentUser },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const filteredInboxes = useMemo(() => inboxes?.filter((inbox) => inbox.isAgentAssigned) ?? [], [inboxes]);

  const setInbox = useCallback(
    (inbox: TChatInbox) => {
      dispatch({
        type: Actions.SET_CHAT,
        payload: {
          selectedInbox: inbox,
        },
      });
    },
    [dispatch],
  );

  const selectInbox = useCallback(() => {
    dispatch({
      type: Actions.SET_CHAT,
      payload: {
        selectedInbox: undefined,
      },
    });
  }, [dispatch]);

  const assignUserToInbox = useCallback(
    async (inbox: TChatInbox) => {
      try {
        if (currentUser) {
          await assignInboxToConversation(currentUser.id, inbox.identifier);
        }
      } catch (error) {
        console.error(error);
        toastErrorsTranslated(error);
      }
    },
    [currentUser],
  );

  return {
    inboxes,
    filteredInboxes,
    selectedInbox,
    selectInbox,
    assignUserToInbox,
    setInbox,
  };
};
