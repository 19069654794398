import React from 'react';
import { getImageByURLHelper } from '../../../helpers/dataHelpers';
import chatAvatar from '../../../assets/images/chat_avatar.png';
import { Label } from '../Label';
import { TChatUser } from '../../../interfaces/TChat';
import { useChatList } from '../../../hooks/chat/useChatList';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { useTime } from '../../../hooks/useTime';
import { useHistory } from 'react-router';
import { CHAT_PAGE_ROUTE } from '../../../constants/routes';
import { useMainDispatch } from '../../../hooks/useMainDispatch';
import { Actions } from '../../../constants/actionTypes';

interface Props {
  chatUser: TChatUser;
  isSelected: boolean;
}

export const ChatCard: React.FC<Props> = ({ chatUser, isSelected }) => {
  const { cvCardWrapper, cvCardName, active } = convertKeysToCamelCase(style);
  const dispatch = useMainDispatch();

  const { id, name, imageUrl, date, labels } = chatUser;

  const { relativeDate } = useTime(date);
  const { setSelectedChatUser } = useChatList();
  const history = useHistory();

  const image = getImageByURLHelper(imageUrl) ?? chatAvatar;

  const selectChatUser = (id: string) => {
    history.push(`${CHAT_PAGE_ROUTE}/${id}`);
    dispatch({
      type: Actions.SET_UI,
      payload: {
        chatPageView: 'conversations',
      },
    });
    setSelectedChatUser(id);
  };

  return (
    <div
      onClick={() => selectChatUser(id)}
      className={`${cvCardWrapper} ${isSelected ? active : 'bg-white'}`}
      role="button"
    >
      <div className="d-flex flex-row align-items-center">
        <img className="circle" src={image} width={40} height={40} />
        <div className={cvCardName}>
          {name}
          <div className="d-flex flex-row">
            {labels?.length > 0 && labels.map((label) => <Label key={label.identifier} labelData={label} />)}
          </div>
        </div>
      </div>
      <small className={`align-self-start m-1 text-muted`}>{relativeDate}</small>
    </div>
  );
};
