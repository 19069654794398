import { Actions } from '../../constants/actionTypes';
import { TChatStateInner } from '../../interfaces/redux/TChatState';
import { TColorStateInner } from '../../interfaces/redux/TColorState';
import { TDesignerStateInner } from '../../interfaces/redux/TDesignerState';
import { TLoginInner } from '../../interfaces/redux/TLoginState';
import { TSettingsStateInner } from '../../interfaces/redux/TSettingsState';
import { TUIStateInner } from '../../interfaces/redux/TUIState';
import { TUserStateInner } from '../../interfaces/redux/TUserState';
import { TReduxHandler, TReduxState } from '../../interfaces/TReduxState';

const setLogIn = (state: TReduxState, payload: Partial<TLoginInner>): TReduxState => {
  return {
    ...state,
    login: { ...state.login, ...payload },
  };
};

const setChatState = (state: TReduxState, payload: Partial<TChatStateInner>): TReduxState => {
  return {
    ...state,
    chat: { ...state.chat, ...payload },
  };
};

const setUIState = (state: TReduxState, payload: Partial<TUIStateInner>): TReduxState => {
  return {
    ...state,
    ui: { ...state.ui, ...payload },
  };
};

const setUserState = (state: TReduxState, payload: Partial<TUserStateInner>): TReduxState => {
  return {
    ...state,
    user: { ...state.user, ...payload },
  };
};

const setSettingsState = (state: TReduxState, payload: Partial<TSettingsStateInner>): TReduxState => {
  return {
    ...state,
    settings: { ...state.settings, ...payload },
  };
};

const setDesignerState = (state: TReduxState, payload: Partial<TDesignerStateInner>): TReduxState => {
  return {
    ...state,
    designer: { ...state.designer, ...payload },
  };
};

const setColorState = (state: TReduxState, payload: Partial<TColorStateInner>): TReduxState => {
  return {
    ...state,
    colors: { ...state.colors, ...payload },
  };
};

export const mainHandler: TReduxHandler = {
  [Actions.SET_LOGIN]: setLogIn,
  [Actions.SET_CHAT]: setChatState,
  [Actions.SET_UI]: setUIState,
  [Actions.SET_USER]: setUserState,
  [Actions.SET_SETTINGS]: setSettingsState,
  [Actions.SET_DESIGNER]: setDesignerState,
  [Actions.SET_COLOR]: setColorState,
};
