import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import chatAvatar from '../../../assets/images/chat_avatar.png';
import style from '../../../assets/scss/components/chat/chat-panel-sidebar/ChatPanelSidebar.module.scss';
import { TChatUser } from '../../../interfaces/TChat';
import MaterialIcon from '../../common/MaterialIcon';
import { Label } from '../Label';
import { getImageByURLHelper } from '../../../helpers/dataHelpers';
import { Actions } from '../../../constants/actionTypes';
import { useMainDispatch } from '../../../hooks/useMainDispatch';
import { AddLabelButton } from './AddLabelButton';

interface IProps {
  currentUser: TChatUser;
}

export const ChatPanelSidebar: React.FC<IProps> = ({ currentUser }) => {
  const { client, userAvatar, chatPanelSidebar, info, conversationLabels, conversationPlaceholder, iconBack, label } =
    convertKeysToCamelCase(style);
  const dispatch = useMainDispatch();

  const { name, imageUrl, userInfo, platform, labels } = currentUser;
  const image = getImageByURLHelper(imageUrl) ?? chatAvatar;

  const closeChatPanelSidebar = () => {
    dispatch({
      type: Actions.SET_UI,
      payload: {
        chatPageView: 'conversations',
      },
    });
  };

  return (
    <div className={chatPanelSidebar}>
      <div className={client}>
        <button onClick={() => closeChatPanelSidebar()} className={iconBack}>
          <MaterialIcon icon={'arrow_back_ios'} />
        </button>
        <img className={`circle ${userAvatar}`} src={image} />
        <div>
          <p>{name}</p>
          <span>{userInfo?.email ?? 'Unavailable'}</span>
        </div>
      </div>

      <div className={info}>
        <div>
          <MaterialIcon icon={'email'} />
          <span>{userInfo?.email ?? 'Unavailable'}</span>
        </div>
        <div>
          <MaterialIcon icon={'call'} />
          <span>{userInfo?.phone ?? 'Unavailable'}</span>
        </div>
        <div>
          <MaterialIcon icon={'work'} />
          <span>{userInfo?.address ?? 'Unavailable'}</span>
        </div>
        <div>
          <MaterialIcon icon={'phonelink_setup'} />
          <span>{platform ?? 'Unavailable'}</span>
        </div>
      </div>

      <div className={conversationLabels}>
        <div>
          <MaterialIcon icon={'bookmark'} />
          <p>Conversation Labels</p>
        </div>
        <span className={label}>
          {labels?.length > 0 &&
            labels.map((label) => <Label key={label.identifier} labelData={label} removable={true} />)}
          <AddLabelButton />
        </span>
      </div>
      <div className={conversationPlaceholder}>
        <div>
          <MaterialIcon icon={'bookmark'} />
          <p>Conversations</p>
        </div>
        <span>-</span>
      </div>
    </div>
  );
};
