import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/SettingsSidebar.module.scss';
import { Accordion, ListGroup } from 'react-bootstrap';
import MaterialIcon from '../../common/MaterialIcon';
import { TSettingsSidebarElement } from '../../../interfaces/ISettings';
import { useSettingsSidebar } from '../../../hooks/settings/useSettingsSidebar';

interface ISidebarElementProps extends TSettingsSidebarElement {
  index: string;
}

export const SidebarElement: React.FC<ISidebarElementProps> = ({ index, icon, title, elements }) => {
  const { sidebarElement, accordionTitle, listWrapper, cvAccordionBody, active, accordionHeaderWrapper } =
    convertKeysToCamelCase(style);

  const { activeDestination, setActiveDestination } = useSettingsSidebar();

  return (
    <Accordion.Item eventKey={index} className={sidebarElement}>
      <Accordion.Header className={`${accordionTitle} ${active}`}>
        <div className={accordionHeaderWrapper}>
          <MaterialIcon icon={icon} /> <p>{title}</p>
        </div>
        <MaterialIcon icon={'arrow_drop_down'} />
      </Accordion.Header>
      <Accordion.Body className={cvAccordionBody}>
        <ListGroup className={listWrapper}>
          {elements.map(({ text, destination }, index) => {
            return (
              <ListGroup.Item
                key={index}
                className={activeDestination === destination ? active : ''}
                onClick={() => setActiveDestination(destination)}
              >
                {text}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SidebarElement;
