import React from 'react';
import { Modal } from 'react-bootstrap';

export const WebclientInfoModal: React.FC = () => {
  return (
    <Modal.Body>
      <p>
        Here you can set a webclient integration, or edit the current one. The webclient is a custom chat interface
        which can be injected to your site by inserting a HTML snippet.
        <br></br>
        <br></br>
        After setting up the webclient you can copy the HTML snippet and insert to your site.
        <br></br>
        <br></br>
        For further information click on the information button on each paragraph subtitle.
      </p>
    </Modal.Body>
  );
};

export default WebclientInfoModal;
