import { AuthenticatedAppRoutes } from '../routes/AuthenticatedAppRoutes';
import { Switch, Route } from 'react-router-dom';
import { IRoute, TRouteType } from '../../interfaces/IRoutes';
import { NotFoundPage } from '../pages/NotFoundPage';
import { RestrictedRoute } from '../routes/RestrictedRoute';

export const AuthenticatedRouter: React.FC = () => {
  return (
    <Switch>
      {AuthenticatedAppRoutes.map((route: IRoute) => {
        const { path, component, type, permissions, exact } = route;

        const routeTypeMap: Record<TRouteType, (route: IRoute) => void> = {
          restricted: ({ path, component, permissions, exact }) => (
            <RestrictedRoute
              key={path}
              requiredPermissions={permissions}
              path={path}
              component={component}
              exact={exact}
            />
          ),
          default: ({ path, component, exact }) => <Route key={path} path={path} component={component} exact={exact} />,
        };

        const routeType = type || 'default';
        return routeTypeMap[routeType]({ path, component, type, permissions, exact });
      })}

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};
