import { PERMISSIONS } from '../../constants/permissions';
import {
  MAIN_PAGE_ROUTE,
  CHAT_PAGE_ROUTE,
  SETTINGS_PAGE_ROUTE,
  LOGOUT_PAGE_ROUTE,
  DESIGNER_PAGE_ROUTE,
} from '../../constants/routes';
import { ChatPage } from '../pages/ChatPage';
import MainPage from '../pages/MainPage';
import { SettingsPage } from '../pages/SettingsPage';
import { DesignerPage } from '../pages/DesignerPage';
import { IRoute } from '../../interfaces/IRoutes';
import { LogoutPage } from '../pages/LogoutPage';

export const AuthenticatedAppRoutes: IRoute[] = [
  {
    path: LOGOUT_PAGE_ROUTE,
    component: LogoutPage,
    exact: true,
  },
  {
    path: MAIN_PAGE_ROUTE,
    component: MainPage,
    exact: true,
  },
  {
    path: `${CHAT_PAGE_ROUTE}/:id?`,
    component: ChatPage,
    type: 'restricted',
    permissions: [PERMISSIONS.CHAT_MANAGEMENT],
  },
  {
    path: SETTINGS_PAGE_ROUTE,
    component: SettingsPage,
    exact: true,
  },
  {
    path: DESIGNER_PAGE_ROUTE,
    component: DesignerPage,
    exact: true,
  },
];
