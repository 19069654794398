import { useCallback } from 'react';
import { createLabelApi, deleteLabelApi, getLabelsApi, updateLabelApi } from '../../../api/label.api';
import { Actions } from '../../../constants/actionTypes';
import { addValueToList, removeValueFromList, replaceValueInList } from '../../../helpers/baseHelpers';
import { toastErrors } from '../../../helpers/toastErrors';
import { TLabel, TLabelIdentifier, TLabelUpdateable } from '../../../interfaces/TChat';
import { useMainDispatch } from '../../useMainDispatch';
import { useLabels } from './useLabels';

export const useLabelActions = () => {
  const { labels } = useLabels();
  const dispatch = useMainDispatch();

  const setSelectedLabel = useCallback(
    (label: TLabel) => {
      dispatch({
        type: Actions.SET_SETTINGS,
        payload: {
          selectedLabel: label,
        },
      });
    },
    [dispatch],
  );

  const createLabel = useCallback(
    async (data: TLabelUpdateable) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        const { data: newLabel } = await createLabelApi(data);

        dispatch({
          type: Actions.SET_SETTINGS,
          payload: {
            labels: addValueToList<TLabel>(newLabel, labels),
          },
        });
      } catch (error) {
        toastErrors(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, labels],
  );

  const updateLabel = useCallback(
    async (id: TLabelIdentifier, data: TLabelUpdateable) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await updateLabelApi(id, data);

        dispatch({
          type: Actions.SET_SETTINGS,
          payload: {
            labels: replaceValueInList<TLabel>(
              { identifier: id, ...data },
              labels ?? [],
              (data) => data.identifier === id,
            ),
          },
        });
      } catch (error) {
        toastErrors(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, labels],
  );

  const deleteLabel = useCallback(
    async (id: TLabelIdentifier) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await deleteLabelApi(id);

        dispatch({
          type: Actions.SET_SETTINGS,
          payload: {
            labels: removeValueFromList<TLabel>((data) => data.identifier === id, labels),
          },
        });
      } catch (error) {
        toastErrors(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, labels],
  );

  const getLabels = useCallback(async () => {
    try {
      const { data: labels } = await getLabelsApi();

      dispatch({
        type: Actions.SET_SETTINGS,
        payload: {
          labels,
        },
      });
    } catch (error) {
      toastErrors(error);
    }
  }, [dispatch]);

  return {
    createLabel,
    updateLabel,
    deleteLabel,
    getLabels,
    setSelectedLabel,
  };
};
