import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import useChat from '../useChat';
import useUpdateEffect from '../useUpdateEffect';
import { useChatInput } from './useChatInput';

export const useChatTyping = () => {
  const { sendAgentTyping } = useChat();
  const { chatMode } = useChatInput();
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const debounceTyping = useDebouncedCallback(() => setIsTyping(false), 3000);

  const setTyping = useCallback(() => {
    // Only send typing indicator for reply mode
    if (chatMode === 'reply') {
      setIsTyping(true);
      debounceTyping();
    }
  }, [chatMode, debounceTyping]);

  useUpdateEffect(() => {
    sendAgentTyping(isTyping);
  }, [sendAgentTyping, isTyping]);

  return {
    setTyping,
  };
};
