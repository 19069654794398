import { useCallback } from 'react';
import { Actions } from '../../constants/actionTypes';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';
import { useCannedResponses } from './useCannedResponses';
import { useChatInput } from './useChatInput';

export const useCannedResponsesMenu = () => {
  const {
    chat: { cannedResponsesIndex },
  } = useMainSelector();
  const { filteredCannedResponses } = useCannedResponses();
  const { setMessage } = useChatInput();
  const dispatch = useMainDispatch();

  const increaseCannedResponseIndex = useCallback(() => {
    if (filteredCannedResponses && cannedResponsesIndex < filteredCannedResponses?.length - 1) {
      dispatch({ type: Actions.SET_CHAT, payload: { cannedResponsesIndex: cannedResponsesIndex + 1 } });
    }
  }, [cannedResponsesIndex, dispatch, filteredCannedResponses]);

  const decreaseCannedResponseIndex = useCallback(() => {
    if (cannedResponsesIndex > 0) {
      dispatch({ type: Actions.SET_CHAT, payload: { cannedResponsesIndex: cannedResponsesIndex - 1 } });
    }
  }, [cannedResponsesIndex, dispatch]);

  const setCurrentResponseBasedOnIndex = useCallback(() => {
    if (filteredCannedResponses && cannedResponsesIndex) {
      const { message } = filteredCannedResponses[cannedResponsesIndex];
      setMessage(message);
      dispatch({ type: Actions.SET_CHAT, payload: { cannedResponsesMode: false } });
    }
  }, [cannedResponsesIndex, dispatch, filteredCannedResponses, setMessage]);

  return {
    cannedResponsesIndex,
    increaseCannedResponseIndex,
    decreaseCannedResponseIndex,
    setCurrentResponseBasedOnIndex,
  };
};
