import { loginApi } from '../../api/userApi';
import { Actions } from '../../constants/actionTypes';
import { IUserLoginPayload } from '../../interfaces/ILogin';
import { useHistory } from 'react-router';
import { LOGIN_PAGE_ROUTE, MAIN_PAGE_ROUTE, OTP_PAGE_ROUTE } from '../../constants/routes';
import { toastErrorTranslated, toastSuccessTranslated } from '../../helpers/toastErrors';
import { useMainDispatch } from '../useMainDispatch';
import { useCallback } from 'react';
import { userDefaults } from '../../redux/initialState';
import { DataKeys } from '../../constants/login';

interface IUseLogin {
  submitLogin: (formData: IUserLoginPayload) => Promise<void>;
  logout: () => void;
}

export const useLogin = (): IUseLogin => {
  const dispatch = useMainDispatch();
  const history = useHistory();

  const submitLogin = useCallback(
    async (formData: IUserLoginPayload) => {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      try {
        const { data } = await loginApi(formData);

        if (data.success) {
          const { email, password } = formData;

          dispatch({
            type: Actions.SET_USER,
            payload: { email, password },
          });
          history.push(OTP_PAGE_ROUTE);
        } else {
          const { refreshToken, accessToken } = data;

          localStorage.setItem(DataKeys.ACCESS_TOKEN_DATA_KEY, accessToken);
          localStorage.setItem(DataKeys.REFRESH_TOKEN_DATA_KEY, refreshToken);

          dispatch({
            type: Actions.SET_LOGIN,
            payload: { refreshToken, accessToken, isLoggedIn: true },
          });
          history.push(MAIN_PAGE_ROUTE);
          toastSuccessTranslated('auth.login.success.message');
        }
      } catch (error) {
        toastErrorTranslated('auth.login.fail.message');
        history.push(LOGIN_PAGE_ROUTE);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, history],
  );

  const logout = useCallback(() => {
    localStorage.removeItem(DataKeys.ACCESS_TOKEN_DATA_KEY);
    localStorage.removeItem(DataKeys.REFRESH_TOKEN_DATA_KEY);
    localStorage.removeItem(DataKeys.SELECTED_COMPANY_DATA_KEY);

    dispatch({
      type: Actions.SET_LOGIN,
      payload: { accessToken: '', refreshToken: '', isLoggedIn: false },
    });
    dispatch({
      type: Actions.SET_USER,
      payload: userDefaults,
    });
  }, [dispatch]);

  return { submitLogin, logout };
};
