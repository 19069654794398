import { useCallback } from 'react';
import {
  getWebclientConfigsEndpoint,
  createWebclientConfigEndpoint,
  updateWebclientConfigEndpoint,
  deleteWebclientConfigEndpoint,
  removeWebclientWhitelistedDomainEndpoint,
  addWebclientWhitelistEndpoint,
} from '../../../api/webclientsApi';
import { Actions } from '../../../constants/actionTypes';
import { useMainDispatch } from '../../useMainDispatch';
import { toastErrors } from '../../../helpers/toastErrors';
import { Config } from '../../../api/dto/webclient.dto';

export const useWebclientActions = () => {
  const dispatch = useMainDispatch();

  const fetchWebclientConfigs = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const { data: webclient } = await getWebclientConfigsEndpoint();
      dispatch({
        type: Actions.SET_SETTINGS,
        payload: {
          webclient: {
            configs: webclient.configs,
            uuid: webclient.uuid,
            botId: webclient?.botId,
            environment: webclient?.environment,
            whitelistedDomains: webclient?.whitelistedDomains,
          },
        },
      });
    } catch (error: any) {
      if (error?.response?.data?.errorCode !== 'NOT_FOUND') {
        toastErrors(error);
      }
      dispatch({
        type: Actions.SET_SETTINGS,
        payload: {
          webclient: {
            configs: [],
            uuid: undefined,
            botId: undefined,
            environment: undefined,
            whitelistedDomains: undefined,
          },
        },
      });
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const createWebclientConfig = useCallback(
    async (name: string, config: Config) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await createWebclientConfigEndpoint(name, config);
        await fetchWebclientConfigs();
      } catch (error: any) {
        // TODO handle errors
        console.error(error);
        toastErrors(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchWebclientConfigs],
  );

  const updateWebclientConfig = useCallback(
    async (identifier: string, name: string, config: Config) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await updateWebclientConfigEndpoint(identifier, name, config);
        await fetchWebclientConfigs();
      } catch (error: any) {
        // TODO handle errors
        console.error(error);
        toastErrors(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchWebclientConfigs],
  );

  const deleteWebclientConfig = useCallback(
    async (identifier: string) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await deleteWebclientConfigEndpoint(identifier);
        await fetchWebclientConfigs();
      } catch (error: any) {
        // TODO handle errors
        console.error(error);
        toastErrors(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchWebclientConfigs],
  );

  const addWhitelistedDomain = useCallback(
    async (domain: string) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await addWebclientWhitelistEndpoint(domain);
        await fetchWebclientConfigs();
      } catch (error: any) {
        // TODO handle errors
        console.error(error);
        toastErrors(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchWebclientConfigs],
  );
  const removeWebclientWhitelistedDomain = useCallback(
    async (whitelistedDomain: string) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await removeWebclientWhitelistedDomainEndpoint(whitelistedDomain);
        await fetchWebclientConfigs();
      } catch (error: any) {
        // TODO handle errors
        console.error(error);
        toastErrors(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchWebclientConfigs],
  );

  return {
    fetchWebclientConfigs,
    createWebclientConfig,
    updateWebclientConfig,
    deleteWebclientConfig,
    addWhitelistedDomain,
    removeWebclientWhitelistedDomain,
  };
};
