import { useCallback } from 'react';
import {
  createAgentsEndpoint,
  updateAgentsEndpoint,
  deleteAgentsEndpoint,
  getAgentsEndpoint,
} from '../../../api/agentsApi';
import { Actions } from '../../../constants/actionTypes';
import { useMainDispatch } from '../../useMainDispatch';
import { CreateAgentRequestDTO } from '../../../api/dto/agent.dto';

export const useAgentActions = () => {
  const dispatch = useMainDispatch();

  const fetchAgents = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const { data: agents } = await getAgentsEndpoint();
      dispatch({ type: Actions.SET_SETTINGS, payload: { agents } });
    } catch (error) {
      // TODO: Handle errors
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const createAgent = useCallback(
    async (agent: CreateAgentRequestDTO) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await createAgentsEndpoint(agent);
        // TODO do not refetch
        await fetchAgents();
      } catch (error) {
        // TODO: Handle errors
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchAgents],
  );

  const editAgent = useCallback(
    async (agentId: string, agentData: CreateAgentRequestDTO) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await updateAgentsEndpoint(agentId, agentData);
        // TODO do not refetch
        await fetchAgents();
      } catch (error) {
        // TODO: Handle errors
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchAgents],
  );

  const deleteAgent = useCallback(
    async (agentId: string) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await deleteAgentsEndpoint(agentId);
        // TODO do not refetch
        await fetchAgents();
      } catch (error) {
        // TODO: Handle errors
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchAgents],
  );

  return {
    createAgent,
    editAgent,
    deleteAgent,
    fetchAgents,
  };
};
