import style from '../../../assets/scss/components/sidebar/AppSidebar.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { SETTINGS_PAGE_ROUTE } from '../../../constants/routes';
import SidebarItem from './SidebarItem';

export const SidebarFooter = () => {
  const { sidebarFooter, wrapper, footer } = convertKeysToCamelCase(style);

  return (
    <div className={sidebarFooter}>
      <SidebarItem icon={'settings'} to={SETTINGS_PAGE_ROUTE} text={'Settings'} />
      <div className={footer}>
        <div className={wrapper}>
          <p>powered by</p>
          <a href="#"> Conversed.ai</a>
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
