import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_EN from '../translations/en.json';

const resources = {
  en: {
    translation: en_EN,
  },
};

const initOptions: InitOptions = {
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

export const initI18N = () => {
  i18n.use(initReactI18next).init(initOptions);
};

export default i18n;
