import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/CustomControls.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { GeneralTooltip } from '../../common/GeneralTooltip';
import { useFlowNode } from '../../../hooks/flow/useFlowNode';
import { useCallback } from 'react';
import { useBlocksActions } from '../../../hooks/flow/block/useBlocksActions';
import { useFlow } from '../../../hooks/flow/flow/useFlow';
import { EFlowDirection } from '../../../interfaces/EFlowDirection';
import { Node } from 'react-flow-renderer';

export const SaveLayoutButton: React.FC = () => {
  const { flowDirection } = useFlow();
  const { getBlocksFromNodes, getNodes } = useFlowNode();
  const { saveFlow } = useBlocksActions();
  const { button } = convertKeysToCamelCase(style);

  const getBlockPosition = useCallback(
    (node?: Node) => {
      if (!node) {
        return {};
      }

      if (flowDirection === EFlowDirection.Vertical) {
        return {
          verticalPosition: node.position,
        };
      }

      return {
        horizontalPosition: node.position,
      };
    },
    [flowDirection],
  );

  const handleSaveFlow = useCallback(() => {
    const blocks = getBlocksFromNodes();
    const nodes = getNodes();

    saveFlow(
      blocks.map((block) => ({
        ...block,
        ...getBlockPosition(nodes.find((node) => node.id === block.id)),
      })),
    );
  }, [getBlocksFromNodes, getNodes, saveFlow, getBlockPosition]);

  return (
    <GeneralTooltip id={'tooltip-flow-layout-save-button'} text={'Save flow'}>
      <div className={button} onClick={() => handleSaveFlow()}>
        <MaterialIcon icon={'save'} />
      </div>
    </GeneralTooltip>
  );
};
