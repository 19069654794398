import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../constants/actionTypes';
import { resetPasswordApi } from '../../api/userApi';
import { useHistory } from 'react-router-dom';
import { LOGIN_PAGE_ROUTE } from '../../constants/routes';
import { toastErrorsTranslated } from '../../helpers/toastErrors';
import { useMainDispatch } from '../useMainDispatch';

interface IUseResetPassword {
  resetPassword: (password: string, code: string) => Promise<void>;
}

export const useResetPassword = (): IUseResetPassword => {
  const dispatch = useMainDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const resetPassword = async (password: string, code: string) => {
    dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
    try {
      await resetPasswordApi({ password, code });
      history.push(LOGIN_PAGE_ROUTE);
      toast.success(t('auth.reset-password.success.message'));
    } catch (error) {
      toastErrorsTranslated(error);
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  };

  return { resetPassword };
};
