import { EBreakpoint } from '../constants/breakpoints';
import { useViewport } from './useViewport';

export const useMedia = (breakpoint: EBreakpoint) => {
  const { width } = useViewport();
  return {
    down: width < breakpoint,
    up: width >= breakpoint,
  };
};
