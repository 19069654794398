import { useState, useMemo, useCallback } from 'react';
import { toastInfoTranslated } from '../../../helpers/toastErrors';
import { createSimpleWebclientSnippet, createAdvancedWebclientSnippet } from '../../../helpers/webclientHelper';
import { useWebclient } from './useWebclient';

export const useWebclientSnippet = () => {
  const { webclient, selectedWebclientConfig } = useWebclient();

  const [snippetType, setSnippetType] = useState<'simple' | 'advanced'>('simple');

  const snippetContent = useMemo(
    () =>
      snippetType === 'simple'
        ? createSimpleWebclientSnippet(webclient, selectedWebclientConfig)
        : createAdvancedWebclientSnippet(webclient, selectedWebclientConfig),
    [snippetType, webclient, selectedWebclientConfig],
  );

  const copySnippetToClipboard = useCallback(() => {
    navigator.clipboard.writeText(snippetContent);
    toastInfoTranslated('Copied to clipboard');
  }, [snippetContent]);

  return { copySnippetToClipboard, snippetContent, snippetType, setSnippetType };
};
