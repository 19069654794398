import { getMaterialIconType } from '../../helpers/iconHelper';
import { TMaterialIconType } from '../../interfaces/IGeneral';

interface IMaterialIconProps {
  icon: string;
  type?: TMaterialIconType;
  classes?: string[];
  onClick?: CallableFunction;
}

export const MaterialIcon: React.FC<IMaterialIconProps> = ({ icon, type, classes, onClick }) => {
  // * Prepare material icons classes
  const iconType = getMaterialIconType(type);
  // * Add optional class names
  const iconClasses = classes ? classes.join(' ') : '';
  return (
    <span onClick={() => onClick?.()} className={`${iconType} ${iconClasses}`}>
      {icon}
    </span>
  );
};

export default MaterialIcon;
