import React from 'react';
import { Modal } from 'react-bootstrap';

export const LabelInfoModal: React.FC = () => {
  return (
    <Modal.Body>
      <p>
        On this tab you can manage your labels. All your registered labels will appear here, and you can edit, remove or
        create new ones.
        <br />
        <br />
        <b>Create</b>
        <br />
        To create new label press the + icon and after filling in the label properties press the submit button. ...
        <br />
        <br />
        <b>Edit</b>
        <br />
        To edit a label press the edit button on the card ...
        <br />
        <br />
        <b>Delete</b>
        <br />
        To remove an label press the delete button on the card ...
      </p>
    </Modal.Body>
  );
};

export default LabelInfoModal;
