import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/designer/DesignerContent.module.scss';
import { DesignerFlow } from './DesignerFlow';
import { DesignerSidebar } from './DesignerSidebar';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useBlocksInit } from '../../hooks/flow/block/useBlocksInit';

export const DesignerContent: React.FC = () => {
  const { loading, error } = useBlocksInit();
  const { designerContentWrapper } = convertKeysToCamelCase(style);

  if (loading || error) {
    return null;
  }

  return (
    <div className={designerContentWrapper}>
      <ReactFlowProvider>
        <DesignerFlow />
        <DesignerSidebar />
      </ReactFlowProvider>
    </div>
  );
};

export default DesignerContent;
