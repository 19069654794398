import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const useTime = (date?: string | number, format?: string) => {
  const timeDiff = (format: 'minutes', date1?: string | number, date2?: string | number) => {
    const time1 = dayjs(date1);
    return dayjs(date2).diff(time1, format);
  };

  return {
    relativeDate: dayjs(date).fromNow(),
    date: dayjs(date).format(format),
    timeDiff,
  };
};
