import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useChatList } from '../../../hooks/chat/useChatList';
import { Button, Form, FormControl } from 'react-bootstrap';
import { ChatSidebarGroupDropdown } from './ChatSidebarGroupDropdown';
import ChatSidebarStatusDropdown from './ChatSidebarStatusDropdown';
import ChatSidebarLabelDropdown from './ChatSidebarLabelDropdown';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';

export const ChatSidebarHeader: React.FC = () => {
  const { selectedChatList, assignedUserCount, unassignedUserCount, setSelectedChatList, setSearchString } =
    useChatList();

  const { chatlistHeader, chatlistTopHeader, chatlistSearch, chatlistTabs, activeTab, searchSubmit } =
    convertKeysToCamelCase(style);

  return (
    <div className={chatlistHeader}>
      <div className={chatlistTopHeader}>
        <ChatSidebarGroupDropdown />
        <ChatSidebarStatusDropdown />
      </div>
      <div className={chatlistSearch}>
        <ChatSidebarLabelDropdown />
        <Form className="d-flex">
          <FormControl
            type="search"
            placeholder="Search"
            className="mr-2"
            aria-label="Search"
            onChange={(text) => {
              setSearchString(text.target.value);
            }}
          />
          <Button className={searchSubmit} variant="outline-success">
            <span className="material-icons">search</span>
          </Button>
        </Form>
      </div>
      <div className={chatlistTabs}>
        <div
          className={selectedChatList === 'assigned-to-me' ? activeTab : ''}
          role="button"
          onClick={() => {
            setSelectedChatList('assigned-to-me');
          }}
        >
          <span>Assigned to me</span>
          <span>{assignedUserCount}</span>
        </div>
        <div
          className={selectedChatList === 'unassigned' ? activeTab : ''}
          role="button"
          onClick={() => {
            setSelectedChatList('unassigned');
          }}
        >
          <span>Unassigned</span>
          <span>{unassignedUserCount}</span>
        </div>
      </div>
    </div>
  );
};
