import * as yup from 'yup';
import { STRONG_PASSWORD_REGEX, DIGITS } from '../constants/regex';

const emailSchema = yup.string().required('required').email('email').max(255, 'maxLength');

const newPasswordSchema = yup
  .string()
  .required('required')
  .matches(STRONG_PASSWORD_REGEX, { message: 'password.form.invalid' });

const currentPasswordSchema = yup.string().required('password.required');

const tokenSchema = yup.string().required('required').matches(DIGITS, { message: '2fa' }).min(1).max(6);

export const getSignInValidationSchema = () =>
  yup.object().shape({
    password: currentPasswordSchema,
    email: emailSchema,
  });

export const get2FATokenValidationSchema = () =>
  yup.object().shape({
    token: tokenSchema,
  });

export const getForgotPasswordValidationSchema = () =>
  yup.object().shape({
    email: emailSchema,
  });

export const getResetPasswordValidationSchema = () =>
  yup.object().shape({
    password: newPasswordSchema,
    passwordCheck: yup.string().oneOf([yup.ref('password'), null], 'password.match'),
  });
