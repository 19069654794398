import ReactFlow from 'react-flow-renderer';
import { DesignerFlowTools } from './tools/DesignerFlowTools';
import { useDagre } from '../../hooks/flow/useDagre';
import { useDesignerFlow } from '../../hooks/flow/useDesignerFlow';
import { useEffect } from 'react';
import { useFlowEvents } from '../../hooks/flow/useFlowEvents';
import { convertBlocksToNodesWithEdges } from '../../helpers/flowHelper';
import { ConnectionLine } from './edges/ConnectionLine';
import { useBlocks } from '../../hooks/flow/block/useBlocks';

export const DesignerFlow: React.FC = () => {
  const { blocks } = useBlocks();
  const { addElementsToDagreAndPositionInLayout } = useDagre();
  const {
    reactFlowWrapper,
    elements,
    setElements,
    onConnect,
    onElementsRemove,
    onElementClick,
    onNodeDragStop,
    onDragOver,
    onDrop,
    onFlowLoad,
    nodeTypes,
    edgeTypes,
  } = useDesignerFlow();

  useFlowEvents();

  useEffect(() => {
    if (!Array.isArray(blocks) || !blocks.length) {
      return;
    }

    // ! These methods need to be together to ensure fitView is called on the canvas (only on init)
    const elements = convertBlocksToNodesWithEdges(blocks);
    const positionedElements = addElementsToDagreAndPositionInLayout(elements);
    setElements(positionedElements);
  }, [addElementsToDagreAndPositionInLayout, blocks, setElements]);

  return (
    <>
      {elements && (
        <ReactFlow
          ref={reactFlowWrapper}
          elements={elements}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onElementsRemove={onElementsRemove}
          onConnect={onConnect}
          onLoad={onFlowLoad}
          onNodeDragStop={onNodeDragStop}
          onDragOver={onDragOver}
          onDrop={onDrop}
          connectionLineComponent={ConnectionLine}
          onElementClick={onElementClick}
          snapToGrid={true}
          snapGrid={[15, 15]}
        >
          <DesignerFlowTools />
        </ReactFlow>
      )}
    </>
  );
};
