import React from 'react';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useChatInboxes } from '../../../hooks/chat/useChatInboxes';

export const ChatSidebarGroupDropdown: React.FC = () => {
  const { filteredInboxes, selectInbox, selectedInbox, setInbox } = useChatInboxes();
  const { groupDropdown, active } = convertKeysToCamelCase(style);

  return (
    <DropdownButton
      className={groupDropdown}
      bsPrefix={groupDropdown}
      id="dropdown-basic-button"
      title={selectedInbox?.name ?? 'Inbox'}
    >
      <Dropdown.Item onClick={() => selectInbox()} className={getCSS(!selectedInbox, active)}>
        Inbox
      </Dropdown.Item>
      {filteredInboxes &&
        filteredInboxes.map((inbox, index) => {
          return (
            <Dropdown.Item onClick={() => setInbox(inbox)} key={index}>
              {inbox.name}
            </Dropdown.Item>
          );
        })}
    </DropdownButton>
  );
};

export default ChatSidebarGroupDropdown;
