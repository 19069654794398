import { IKeyString } from '../interfaces/IGeneral';

export const convertKeysToCamelCase = (styleObject: IKeyString): IKeyString => {
  const newStyleObject: IKeyString = Object.create(null);
  Object.keys(styleObject).forEach((key) => {
    let newKey = key.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', ''));
    newKey = newKey.replace('-', '');

    Object.assign(newStyleObject, {
      [newKey]: styleObject[key],
    });
  });

  return newStyleObject;
};

export const getCSS = (condition: boolean, className: string) => (condition ? className : '');

export const replaceValueInList = <T>(value: T, list: T[], condition: (value: T) => boolean) => {
  const valueIndex = list.findIndex(condition);

  // * Add or replace a user based on whether they exist in the chat list
  if (valueIndex > -1) {
    // * Replace user with the one from the backend
    const newList = [...list.slice(0, valueIndex), value, ...list.slice(valueIndex + 1)];
    return newList;
  }

  return addValueToList<T>(value, list);
};

export const addValueToList = <T>(value: T, list?: T[]) => {
  if (!list) {
    return [value];
  }

  return [...list, value];
};

export const removeValueFromList = <T>(condition: (value: T) => boolean, list?: T[]) => {
  if (!list) {
    return [];
  }

  const valueIndex = list.findIndex(condition);

  // * Add or replace a user based on whether they exist in the chat list
  if (valueIndex > -1) {
    // * Replace user with the one from the backend
    const newList = [...list.slice(0, valueIndex), ...list.slice(valueIndex + 1)];
    return newList;
  }

  return list;
};

export const pushUnique = <T>(value: T, list?: T[]) => {
  if (!list) {
    return [value];
  }

  if (list.indexOf(value) === -1) {
    return [...list, value];
  }
};
