import React from 'react';
import style from '../../assets/scss/components/pages/MainPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { DesignerContent } from '../designer/DesignerContent';

export const DesignerPage: React.FC = () => {
  const { wrapper } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <DesignerContent />
    </div>
  );
};
