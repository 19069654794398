import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/BlocksSearch.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { useFlowSearch } from '../../../hooks/flow/useFlowSearch';
import { GeneralTooltip } from '../../common/GeneralTooltip';

export const BlocksSearch: React.FC = () => {
  const { searchStatus, search, button, container } = convertKeysToCamelCase(style);
  const { getNextMatch, getPreviousMatch, searchString, setSearchString, blockSearchMatchStatus } = useFlowSearch();

  return (
    <>
      <div className={container}>
        {blockSearchMatchStatus && <div className={searchStatus}>{blockSearchMatchStatus}</div>}
        <input
          onChange={(e) => setSearchString(e?.target?.value ?? '')}
          className={search}
          type={'text'}
          value={searchString}
          placeholder="Search for blocks in flow"
        />
        <GeneralTooltip id={'tooltip-flow-prev-match-button'} text={'Go to previous match'}>
          <div className={button} onClick={() => getPreviousMatch()}>
            <MaterialIcon icon={'chevron_left'} />
          </div>
        </GeneralTooltip>
        <GeneralTooltip id={'tooltip-flow-next-match-button'} text={'Go to next match'}>
          <div className={button} onClick={() => getNextMatch()}>
            <MaterialIcon icon={'chevron_right'} />
          </div>
        </GeneralTooltip>
        <GeneralTooltip id={'tooltip-flow-close-match-button'} text={'Clear search text'}>
          <div className={button} onClick={() => setSearchString('')}>
            <MaterialIcon icon={'close'} />
          </div>
        </GeneralTooltip>
      </div>
    </>
  );
};
