import { Config, IWebclientConfigItem } from '../api/dto/webclient.dto';
import { TWebclient } from '../interfaces/redux/TSettingsState';

export const defaultWebclientConfig: Config = {
  options: {
    useAttachment: false,
    menuTemplate: [],
    messagePlaceholder: 'Type here',
    welcomeMessage: 'Hi there! I am here to chat with you. Ready to start? 😊',
    defaultState: 'greeting',
    headerType: 'normal',
    title: 'Conversed',
    subtitle: 'AI powered chatbot',
    refParam: 'normal',
    showCloseButton: false,
    greetingTimeout: 500,
    greeting: {
      buttonText: "Let's go!",
      messageIcon: '',
    },
    icons: {
      chatIcon: {
        open: 'https://s3.eu-central-1.amazonaws.com/webclient-connector-staging.conversed.biz/assets/opened.svg',
        closed: 'https://s3.eu-central-1.amazonaws.com/webclient-connector-staging.conversed.biz/assets/closed.svg',
      },
      logo: {
        type: 'circle',
        path: 'https://s3.eu-central-1.amazonaws.com/webclient-connector-staging.conversed.biz/assets/conversed_logo.png',
      },
      speechIcon:
        'https://s3.eu-central-1.amazonaws.com/webclient-connector-staging.conversed.biz/assets/conversed_logo.png',
    },
  },
  scheme: {
    header: {
      headerColor: '#3866A2',
      headerTitleColor: '#FFFFFF',
      headerSubtitleColor: '#FFFFFF',
      windowColor: '#FFFFFF',
      messageBarColor: '#F2F2F2',
    },
    message: {
      messageBubbleColor: '#3866A2',
      messageBubbleTextColor: '#FFFFFF',
      personalMessageBubbleColor: '#F7F7F7',
      personalMessageBubbleTextColor: '#3D3D3D',
      buttonEdgeColor: '#3866A2',
      buttonTextColor: '#3866A2',
      buttonHoverColor: '#90a9ca',
      carouselBackgroundColor: '#FFFFFF',
      carouselButtonColor: '#3866A2',
      carouselTextColor: '#000000',
      carouselButtonTextColor: '#FFFFFF',
      submitButtonColor: '#3866A2',
    },
    greeting: {
      titleColor: '#FFFFFF',
      subtitleColor: '#FFFFFF',
      buttonTextColor: '#FFFFFF',
      buttonColor: '#3866A2',
      backgroundColor: '#FFFFFF',
    },
  },
};

const createWebclientConfig = (webclient: TWebclient, selectedWebclientConfig?: IWebclientConfigItem, config?: any) => `
<script 
src="https://${webclient.uuid}-webclient${webclient.environment === 'stage' ? '-stage' : ''}.conversed.biz/connect.js"
>
</script>
<script>
Conversed.init('${webclient.uuid}', ${JSON.stringify(config, null, 2)}, '${selectedWebclientConfig?.identifier}');
</script>
`;

export const createSimpleWebclientSnippet = (webclient: TWebclient, selectedWebclientConfig?: IWebclientConfigItem) =>
  createWebclientConfig(webclient, selectedWebclientConfig, { botId: webclient.botId });

export const createAdvancedWebclientSnippet = (webclient: TWebclient, selectedWebclientConfig?: IWebclientConfigItem) =>
  createWebclientConfig(webclient, selectedWebclientConfig, {
    botId: webclient.botId,
    ...selectedWebclientConfig?.config,
  });
