import uuid from 'short-uuid';
import { Actions } from '../../constants/actionTypes';
import { useCallback } from 'react';
import { useMainSelector } from '../useMainSelector';
import { useMainDispatch } from '../useMainDispatch';
import { useChat } from '../useChat';
import { TChatHistory } from '../../interfaces/IMessage';

export const useChatInput = () => {
  const { sendMessage, sendNote } = useChat();
  const {
    chat: { chatInputMessage, chatMode, currentUserMessages },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const setMessage = useCallback(
    (message: string) => {
      dispatch({ type: Actions.SET_CHAT, payload: { chatInputMessage: message } });
    },
    [dispatch],
  );

  const setInputMode = useCallback(
    (mode: 'reply' | 'note') => {
      dispatch({
        type: Actions.SET_CHAT,
        payload: { chatMode: mode },
      });
    },
    [dispatch],
  );

  const sendInputMessage = useCallback(() => {
    if (chatInputMessage) {
      const messageObject: TChatHistory = {
        direction: 'out',
        id: uuid.generate().toString(),
        message: {
          text: chatInputMessage,
        },
        time: new Date().toISOString(),
        type: chatMode === 'reply' ? 'text' : 'note', // set it as either text or note
      };

      dispatch({
        type: Actions.SET_CHAT,
        payload: { currentUserMessages: [...currentUserMessages, messageObject] },
      });
      chatMode === 'reply' ? sendMessage(chatInputMessage) : sendNote(chatInputMessage);
      setMessage('');
    }
  }, [chatInputMessage, dispatch, currentUserMessages, chatMode, sendMessage, sendNote, setMessage]);

  return {
    chatMode,
    message: chatInputMessage,
    setMessage,
    setInputMode,
    sendMessage: sendInputMessage,
  };
};
