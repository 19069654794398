import { Actions } from '../constants/actionTypes';
import { useMainDispatch } from './useMainDispatch';
import { useMainSelector } from './useMainSelector';

export const useSidebar = () => {
  const {
    ui: { sideBarOpen },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const toggleSideBar = () =>
    dispatch({
      type: Actions.SET_UI,
      payload: { sideBarOpen: !sideBarOpen },
    });

  return { sideBarOpen, toggleSideBar };
};
