export const ENDPOINTS = {
  OAUTH_LOGIN: '/oauth/token',
  FORGOT_PASSWORD: '/password-reset-email',
  CHECK_RESET_PASSWORD_TOKEN: '/check-reset-code',
  RESET_PASSWORD: 'password-reset',
  USER_DATA: '/me',
  USER_ROUTE: '/me/permissions',
  CONVERSATIONS: '/conversations',
  CANNED_RESPONSES: '/canned-responses',
  AGENTS: '/agents',
  INBOXES: '/inboxes',
  FLOW: {
    BLOCKS: '/flow/blocks',
    LAYOUT: '/flow/layout',
  },
  LABEL: '/label',
  BOT: '/bot',
  WEBCLIENT: '/integrations/webclient',
};
