import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';
import { useAgents } from '../../hooks/settings/agents/useAgents';

import chatAvatar from '../../assets/images/chat_avatar.png';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useAgentsInit } from '../../hooks/settings/agents/useAgentsInit';

export const AgentSettings: React.FC = () => {
  useAgentsInit();
  const { agents, setSelectedAgent } = useAgents();
  const { showModal } = useModal();

  return (
    <>
      {agents?.length ? (
        <>
          <ContentTitle title={'Agents'} onClickInfo={() => showModal('agent-info')} />
          <AddButton text={'Add agents'} onClickAdd={() => showModal('add-agent')} />
          <SettingsCardContainer>
            {agents.map((agent) => (
              <SettingsCard
                key={agent.email}
                title={`${agent.firstName} ${agent.lastName}`}
                subtitle={agent.skills?.join(', ') || '-'}
                avatar={agent.profilePic ?? chatAvatar}
                actions={[
                  {
                    icon: 'border_color',
                    action: () => {
                      showModal('edit-agent');
                      setSelectedAgent(agent);
                    },
                  },
                  {
                    icon: 'delete',
                    action: () => {
                      showModal('delete-agent');
                      setSelectedAgent(agent);
                    },
                  },
                ]}
              />
            ))}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState
          message={'It is quite empty around here. Enlarge your team by adding some agents'}
          addButton={() => showModal('add-agent')}
        />
      )}
    </>
  );
};
export default AgentSettings;
