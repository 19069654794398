import { useCallback } from 'react';
import { putBlockApi, putBlocksApi, getBlocksApi, postBlockApi } from '../../../api/flowApi';
import { Actions } from '../../../constants/actionTypes';
import { addValueToList, replaceValueInList } from '../../../helpers/baseHelpers';
import { toastErrorsTranslated } from '../../../helpers/toastErrors';
import { TBlock, TBlocksConfigWithPositions, TBlockWithPosition } from '../../../interfaces/TDesigner';
import { useMainDispatch } from '../../useMainDispatch';
import { useBlocks } from './useBlocks';

export const useBlocksActions = () => {
  const { blocks } = useBlocks();
  const dispatch = useMainDispatch();

  const updateBlock = useCallback(async (newBlock: TBlock) => {
    try {
      await putBlockApi(newBlock);
      return newBlock;
    } catch (error) {
      console.error(error);
      toastErrorsTranslated(error);
    }
  }, []);

  const updateBlockContent = useCallback(
    (block: TBlockWithPosition) => {
      dispatch({
        type: Actions.SET_DESIGNER,
        payload: { blocks: replaceValueInList(block, blocks ?? [], (iblock) => iblock.id === block.id) },
      });
    },
    [blocks, dispatch],
  );

  const addBlock = useCallback(
    async (block: TBlockWithPosition) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await postBlockApi(block);

        dispatch({
          type: Actions.SET_DESIGNER,
          payload: { blocks: addValueToList(block, blocks ?? []) },
        });
      } catch (error) {
        toastErrorsTranslated(error);
        console.error(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [blocks, dispatch],
  );

  const getBlocks = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const { data: blocks } = await getBlocksApi();

      dispatch({ type: Actions.SET_DESIGNER, payload: { blocks } });
      return 'success';
    } catch (error) {
      console.error(error);
      return 'error';
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const saveFlow = useCallback(
    async (blocks: TBlocksConfigWithPositions) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await putBlocksApi(blocks);
      } catch (error) {
        toastErrorsTranslated(error);
        console.error(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch],
  );

  return { addBlock, updateBlock, updateBlockContent, getBlocks, saveFlow };
};
