import React from 'react';
import { Dropdown } from 'react-bootstrap';
import style from '../../../assets/scss/components/chat/chat-panel-header/InboxDropDown.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import MaterialIcon from '../../common/MaterialIcon';
import { useChatInboxes } from '../../../hooks/chat/useChatInboxes';

export const InboxDropDown: React.FC = () => {
  const { inboxes, assignUserToInbox } = useChatInboxes();
  const { buttonInbox, item, title, list, divider, menu, arrow } = convertKeysToCamelCase(style);

  return (
    <Dropdown className={buttonInbox} bsPrefix={buttonInbox}>
      <Dropdown.Toggle className="btn-stroke">
        <p>Inbox</p> <MaterialIcon classes={[arrow]} icon={'arrow_drop_down'} />
      </Dropdown.Toggle>

      <Dropdown.Menu className={menu}>
        <Dropdown.Item eventKey="4" className={`${item} ${title}`}>
          <p>Inboxes</p>
        </Dropdown.Item>
        <Dropdown.Divider className={divider} />
        {inboxes &&
          inboxes.map((inbox) => {
            return (
              <Dropdown.Item
                key={inbox.identifier}
                className={`${item} ${list}`}
                onClick={() => assignUserToInbox(inbox)}
              >
                <p>{inbox.name}</p>
              </Dropdown.Item>
            );
          })}
        <Dropdown.Divider className={divider} />
        <Dropdown.Item eventKey="4" className={`${item} ${title}`}>
          <p>Group Name</p>
        </Dropdown.Item>
        <Dropdown.Divider className={divider} />
        <Dropdown.Item className={`${item} ${list}`} href="#/action-3">
          <p>Annette Black</p>
        </Dropdown.Item>
        <Dropdown.Item className={`${item} ${list}`} href="#/action-3">
          <p>Devon Lane</p>
        </Dropdown.Item>
        <Dropdown.Item className={`${item} ${list}`} href="#/action-3">
          <p>Savannah Nguyen</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default InboxDropDown;
