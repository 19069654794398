import React, { useCallback } from 'react';
import { useBlocks } from '../../../../hooks/flow/block/useBlocks';
import { useBlocksActions } from '../../../../hooks/flow/block/useBlocksActions';
import { useFlow } from '../../../../hooks/flow/flow/useFlow';
import { EFlowDirection } from '../../../../interfaces/EFlowDirection';
import { TBlock, TBlocksFormData, TBlockWithPosition } from '../../../../interfaces/TDesigner';
import { BlockDataModal } from './BlockDataModal';

export const CreateBlockModal: React.FC = () => {
  const { addBlock } = useBlocksActions();
  const { flowDirection } = useFlow();
  const { newBlock } = useBlocks();

  const getBlockTemplateByType = useCallback((): TBlock => {
    switch (newBlock?.type) {
      case 'text': {
        return {
          id: '',
          type: 'text',
          textToSend: { en: '' },
          destination: [''],
        };
      }
      default: {
        return {
          id: '',
          type: 'button',
          buttonText: { en: '' },
          destination: [''],
        };
      }
    }
  }, [newBlock]);

  const addPositionDataToBlock = useCallback(
    (block: TBlock): TBlockWithPosition => {
      if (flowDirection === EFlowDirection.Vertical) {
        return {
          ...block,
          verticalPosition: newBlock?.position,
        };
      }

      return {
        ...block,
        horizontalPosition: newBlock?.position,
      };
    },
    [newBlock, flowDirection],
  );

  const onSubmit = async (blockData: TBlocksFormData) => {
    const block: TBlock = getBlockTemplateByType();
    const blockWithPosition = addPositionDataToBlock(block);

    addBlock({ ...blockWithPosition, id: blockData.id ?? '' });
  };

  return (
    <>
      <BlockDataModal description={'Give this block an id'} onSubmit={onSubmit} />
    </>
  );
};

export default CreateBlockModal;
