import { useCallback } from 'react';
import { Actions } from '../../constants/actionTypes';
import { removeValueFromList, replaceValueInList } from '../../helpers/baseHelpers';
import { TLabelIdentifier } from '../../interfaces/TChat';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';

export const useConversationLabelActions = () => {
  const {
    chat: { currentUser, chatList },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  //TODO: Add label addition
  const removeLabelFromConversation = useCallback(
    (labelId: TLabelIdentifier) => {
      if (!currentUser?.id) {
        return;
      }

      //TODO: Add API to remove label from user
      const newChatUser = {
        ...currentUser,
        labels: removeValueFromList((label) => label.identifier === labelId, currentUser.labels),
      };

      dispatch({
        type: Actions.SET_CHAT,
        payload: {
          currentUser: newChatUser,
          chatList: replaceValueInList(newChatUser, chatList, (user) => user.id === newChatUser.id),
        },
      });
    },
    [chatList, currentUser, dispatch],
  );

  return {
    removeLabelFromConversation,
  };
};
