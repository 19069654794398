import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGetBlocksAPI, TPutBlockAPI, TPutBlocksAPI, TPostBlockAPI } from './dto/flow.dto';

export const getBlocksApi: TGetBlocksAPI = () => axiosInterceptAccessTokenExpiry().get(ENDPOINTS.FLOW.BLOCKS);

export const putBlockApi: TPutBlockAPI = (block) =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.FLOW.BLOCKS}/${block?.id}`, block);

export const putBlocksApi: TPutBlocksAPI = (blocks) =>
  axiosInterceptAccessTokenExpiry().put(ENDPOINTS.FLOW.BLOCKS, blocks);

export const postBlockApi: TPostBlockAPI = (block) =>
  axiosInterceptAccessTokenExpiry().post(ENDPOINTS.FLOW.BLOCKS, block);
