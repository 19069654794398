import { Picker } from 'emoji-mart';
import styles from '../../../assets/scss/components/chat/ChatInput.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useChatEmojiPicker } from '../../../hooks/chat/useChatEmojiPicker';
import { MaterialIcon } from '../../common/MaterialIcon';
import 'emoji-mart/css/emoji-mart.css';

export const ChatEmojiPicker = () => {
  const { pickerContainerRef, showPicker, appendEmoji, togglePicker } = useChatEmojiPicker();
  const { emojiContainer, icon, iconEmoji } = convertKeysToCamelCase(styles);

  return (
    <span className={`${icon} ${iconEmoji}`} ref={pickerContainerRef}>
      <MaterialIcon icon={'sentiment_satisfied_alt'} onClick={() => togglePicker()} />
      {showPicker && (
        <div className={emojiContainer}>
          <Picker color={'#3866A2'} onSelect={appendEmoji} showSkinTones={false} showPreview={false} />
        </div>
      )}
    </span>
  );
};
