import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/ModalForm.module.scss';
import { useAgents } from '../../../../hooks/settings/agents/useAgents';
import { useAgentActions } from '../../../../hooks/settings/agents/useAgentActions';
import { useModal } from '../../../../hooks/modal/useModal';

export const DeleteAgentModal: React.FC = () => {
  const { modalSection, footer, text } = convertKeysToCamelCase(style);
  const { selectedAgent: agent } = useAgents();
  const { deleteAgent } = useAgentActions();
  const { hideModal } = useModal();

  return (
    <>
      {agent && (
        <Modal.Body>
          <div>
            <div className={`${modalSection}`}>
              <p className={`${text}`}>
                Are you sure you want to delete agent{' '}
                <b>
                  {agent.firstName} {agent.lastName}
                </b>{' '}
                ({agent.email}).
              </p>
            </div>
          </div>
          <div className={`${footer}`}>
            <Modal.Footer>
              <Button
                onClick={() => {
                  deleteAgent(agent.id);
                  hideModal();
                }}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  hideModal();
                }}
              >
                Abort
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteAgentModal;
