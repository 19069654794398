import React from 'react';
import { Button, Modal, Form, Row, Col, Container } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/ModalForm.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { CreateAgentRequestDTO } from '../../../../api/dto/agent.dto';

type Props = {
  agentData?: Partial<CreateAgentRequestDTO>;
  onSubmit: (data: CreateAgentRequestDTO) => void;
};

interface IAgentFormData {
  firstName: string;
  lastName: string;
  email: string;
  skills: string;
}

export const AgentDataModal: React.FC<Props> = ({ agentData, onSubmit }) => {
  const { inputForm, inputGroup, input, modalSection, modalInput, footer } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm<IAgentFormData>({
    shouldUseNativeValidation: true,
    defaultValues: {
      ...agentData,
      skills: agentData?.skills?.join(', ') || '',
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: IAgentFormData) => {
    onSubmit({
      ...data,
      skills: data.skills.split(',').map((skill) => skill.trim()),
    });
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <div>
          <div className={`${modalSection} ${modalInput}`}>
            <Form onSubmit={handleSubmit<IAgentFormData>(hideAndSubmit)}>
              <Row className={`${inputGroup}`}>
                <Container fluid>
                  <Row>
                    <Col>
                      <Form.Group className={`${inputForm}`}>
                        <p>First name</p>
                        <Form.Control
                          className={input}
                          {...register('firstName', { required: 'Please enter the agents first name' })}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className={`${inputForm}`}>
                        <p>Last name</p>
                        <Form.Control className={input} {...register('lastName')} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
                <Form.Group className={`${inputForm}`}>
                  <p>Email</p>
                  <Form.Control className={input} {...register('email')} />
                </Form.Group>
                <Form.Group className={`${inputForm}`}>
                  <p>Skills</p>
                  <Form.Control className={input} {...register('skills')} />
                </Form.Group>
              </Row>
              <div className={`${footer}`}>
                <Modal.Footer>
                  <Button type="submit">Save</Button>
                </Modal.Footer>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AgentDataModal;
