import React from 'react';
import style from '../../assets/scss/components/pages/MainPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { Greeting } from '../common/Greeting';
import { useMainSelector } from '../../hooks/useMainSelector';

export const MainPage: React.FC = () => {
  const { wrapper, greetingText } = convertKeysToCamelCase(style);
  const {
    user: { currentUser },
  } = useMainSelector();

  if (!currentUser) {
    return <></>;
  }

  const { firstName, lastName } = currentUser;

  return (
    <>
      <div className={wrapper}>
        <Greeting className={greetingText} text={`Hi ${firstName} ${lastName}`} />
      </div>
    </>
  );
};

export default MainPage;
