import { useCallback, useMemo } from 'react';
import { Actions } from '../../constants/actionTypes';
import { TChatListFilter, TChatUser } from '../../interfaces/TChat';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';
import { useChatInboxes } from './useChatInboxes';

const chatFilters: Record<TChatListFilter, (chat: TChatUser) => boolean> = {
  'assigned-to-me': (chat: TChatUser) => !!chat.assignedToAgent,
  unassigned: (chat: TChatUser) => !chat.assignedToAgent,
};

export const useChatList = () => {
  const {
    chat: { chatList, selectedChatList, searchString, currentUser },
  } = useMainSelector();
  const { selectedInbox, filteredInboxes } = useChatInboxes();
  const dispatch = useMainDispatch();

  // * Check if queued users should be shown to the current agent
  const usersInInbox = useMemo(
    () =>
      chatList.filter(
        ({ inbox: userInbox }) =>
          !userInbox || // * Accept also users without a queue (for now)
          selectedInbox?.identifier === userInbox || // * If agent is in queue mode, check if user belongs to this queue
          (!selectedInbox && filteredInboxes?.find((inbox) => inbox.identifier === userInbox)), // * Else check if user is in any queue the agent has access to
      ),
    [chatList, selectedInbox, filteredInboxes],
  );

  // * Filter queued users based on, search param and selected chatlist
  const filteredChatList = useMemo(
    () =>
      usersInInbox
        .filter(chatFilters[selectedChatList])
        .filter(({ name }) => name.toLowerCase().includes(searchString.toLowerCase())),
    [usersInInbox, selectedChatList, searchString],
  );

  // * User counts for sidebar panel
  // ? Consider which count to show on search queries (search filtered or unfiltered)
  const assignedUserCount = useMemo(() => usersInInbox.filter(chatFilters['assigned-to-me']).length, [usersInInbox]);
  const unassignedUserCount = useMemo(() => usersInInbox.filter(chatFilters['unassigned']).length, [usersInInbox]);

  const setSelectedChatList = useCallback(
    (selectedChatList: TChatListFilter) => {
      dispatch({ type: Actions.SET_CHAT, payload: { selectedChatList } });
    },
    [dispatch],
  );

  const setSearchString = useCallback(
    (searchString: string) => {
      dispatch({ type: Actions.SET_CHAT, payload: { searchString } });
    },
    [dispatch],
  );

  const setSelectedChatUser = useCallback(
    (chatUserId: string) => {
      const chatUser = chatList.find(({ id }) => id === chatUserId);
      dispatch({
        type: Actions.SET_CHAT,
        payload: {
          currentUser: chatUser,
        },
      });
    },
    [chatList, dispatch],
  );

  return {
    chatList: filteredChatList,
    unassignedUserCount,
    assignedUserCount,
    selectedChatList,
    setSelectedChatList,
    setSearchString,
    selectedChatUser: currentUser,
    setSelectedChatUser,
  };
};
