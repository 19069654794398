import { Actions } from '../constants/actionTypes';
import { useMainSelector } from './useMainSelector';
import { useMainDispatch } from './useMainDispatch';

export const useAppBar = () => {
  const {
    ui: { profileDropdownOpen },
    user: { currentUser },
  } = useMainSelector();

  const dispatch = useMainDispatch();

  const toggleProfileDropdown = () => {
    dispatch({ type: Actions.SET_UI, payload: { profileDropdownOpen: !profileDropdownOpen } });
  };

  return { loggedInUser: currentUser, profileDropdownOpen, toggleProfileDropdown };
};
