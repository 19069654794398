import React from 'react';
import { Button, Modal, Form, Row } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/ModalForm.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { TLabelUpdateable } from '../../../../interfaces/TChat';

type Props = {
  data?: Partial<TLabelFormData>;
  onSubmit: (data: TLabelFormData) => void;
};

type TLabelFormData = TLabelUpdateable;

export const LabelDataModal: React.FC<Props> = ({ data, onSubmit }) => {
  const { inputForm, inputGroup, input, modalSection, modalInput, footer } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm<TLabelFormData>({
    shouldUseNativeValidation: true,
    defaultValues: {
      labelName: data?.labelName,
      labelColor: data?.labelColor,
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: TLabelFormData) => {
    onSubmit({
      labelName: data.labelName,
      labelColor: data.labelColor,
    });
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <div>
          <div className={`${modalSection} ${modalInput}`}>
            <Form onSubmit={handleSubmit<TLabelFormData>(hideAndSubmit)}>
              <Row className={`${inputGroup}`}>
                <Form.Group className={`${inputForm}`}>
                  <p>Label Name</p>
                  <Form.Control
                    className={input}
                    {...register('labelName', { required: 'Please enter the label name' })}
                  />
                </Form.Group>
                <Form.Group className={`${inputForm}`}>
                  <p>Label Color</p>
                  <Form.Control type="color" {...register('labelColor', { required: 'Please enter the color' })} />
                </Form.Group>
              </Row>
              <div className={`${footer}`}>
                <Modal.Footer>
                  <Button type="submit">Save</Button>
                </Modal.Footer>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default LabelDataModal;
