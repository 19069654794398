const hasPermission = (permission: string, routes: string[]) => {
  return routes.includes(permission);
};

export const checkPermissions = (permissions?: string[], availablePermissions?: string[]) => {
  if (!availablePermissions) {
    return true;
  }

  if (!permissions) {
    return true;
  }

  return permissions.every((requiredPermission) => hasPermission(requiredPermission, availablePermissions));
};
