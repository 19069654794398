export const PERMISSIONS = {
  // PERMISSION MANAGEMENT PERMISSIONS
  PERMISSION_MANAGEMENT: 'permission:manage',
  // PROMOTIONS PERMISSIONS
  PROMOTION_MANAGEMENT: 'promotion:manage',
  // REPORTING PERMISSIONS
  REPORTING_VIEW: 'report:view',
  REPORTING_NLP: 'report:NLP',
  // CHAT PERMISSIONS
  CHAT_MANAGEMENT: 'chat:manage',
  // GDPR PERMISSIONS
  GDPR_MANAGEMENT: 'GDPR:manage',
  // User PERMISSIONS
  USER_MANAGEMENT: 'users:manage',
  // KILLSWITCH PERMISSIONS
  KILLSWITCH_MANAGEMENT: 'killswitch:manage',
  // NLP PERMISSIONS
  NLP_MANAGE: 'NLP:manage',
  NLP_CONFIG: 'NLP:config',
  NLP_CHARTS: 'NLP:charts',

  // BLOCK PERMISSIONS
  BLOCK_MANAGE: 'blocks:manage',

  INTERNAL_REPORT_MANAGE: 'internal-report:manage',
  INTERNAL_REPORT_CHARTS: 'internal-report:charts',
  REPORTING_VIEW_CHARTS: 'internal-mongo-charts:view',
  REPORTING_CONFIG_CHARTS: 'internal-mongo-charts:config',
  CANNED_RESPONSES_CONFIG: 'canned-responses:config',
};
