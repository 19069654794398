import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/ModalForm.module.scss';
import { useModal } from '../../../../hooks/modal/useModal';
import { useLabels } from '../../../../hooks/settings/labels/useLabels';
import { useLabelActions } from '../../../../hooks/settings/labels/useLabelActions';

export const DeleteLabelModal: React.FC = () => {
  const { modalSection, footer, text } = convertKeysToCamelCase(style);
  const { hideModal } = useModal();
  const { selectedLabel } = useLabels();
  const { deleteLabel } = useLabelActions();

  return (
    <>
      {selectedLabel && (
        <Modal.Body>
          <div>
            <div className={`${modalSection}`}>
              <p className={`${text}`}>
                Are you sure you want to delete label: <b>{selectedLabel.labelName}</b>?
              </p>
            </div>
          </div>
          <div className={`${footer}`}>
            <Modal.Footer>
              <Button
                onClick={() => {
                  deleteLabel(selectedLabel.identifier);
                  hideModal();
                }}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  hideModal();
                }}
              >
                Abort
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteLabelModal;
