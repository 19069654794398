import styles from '../../../../assets/scss/components/chat/chat-content/messages/TextMessage.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';

interface ITextMessageProps {
  messageLocation: string;
  text: string;
}

export const TextMessage: React.FC<ITextMessageProps> = ({ messageLocation, text }) => {
  const { messageOut, messageIn } = convertKeysToCamelCase(styles);
  const messageClass = messageLocation === 'out' ? messageOut : messageIn;

  return (
    <div className={messageClass}>
      <p>{text}</p>
    </div>
  );
};

export default TextMessage;
