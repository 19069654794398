import React, { useCallback } from 'react';
import { useMainSelector } from '../../hooks/useMainSelector';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/Main.module.scss';
import { SettingsSidebarRoutes } from '../routes/SettingsSidebarRoutes';

export const SettingsContent: React.FC = () => {
  const {
    ui: { settingsPageView },
  } = useMainSelector();
  const { settingsContentWrapper } = convertKeysToCamelCase(style);

  const renderSettingsContent = useCallback(() => {
    const route = SettingsSidebarRoutes.flatMap((routeGroups) => routeGroups.elements).find(
      (route) => route.destination === settingsPageView,
    );
    const Component = route?.component;
    return Component ? <Component /> : <></>;
  }, [settingsPageView]);

  return <div className={settingsContentWrapper}>{renderSettingsContent()}</div>;
};

export default SettingsContent;
