import { useCallback, useEffect, useRef } from 'react';
import { Actions } from '../constants/actionTypes';
import { ESocketEvents } from '../constants/ESocketEvents';
import { TNewCustomerMessagePayload, TChatHistory } from '../interfaces/IMessage';
import { io, Socket } from 'socket.io-client';
import { WS_ENDPOINT } from '../constants/env';
import { socketIOSettings } from '../config/socket.io';
import { TChatUser } from '../interfaces/TChat';
import { useMainDispatch } from './useMainDispatch';
import { useMainSelector } from './useMainSelector';
import { useHistory } from 'react-router';
import { CHAT_PAGE_ROUTE } from '../constants/routes';
import { TCurrentUser } from '../interfaces/IAuth';
import { replaceValueInList } from '../helpers/baseHelpers';
import { getConversationLabelsByIdApi } from '../api/chatApi';

export const useSocketEvents = () => {
  const {
    chat: { currentUserMessages, currentUser, chatList },
    user: { currentUser: currentlyLoggedInUser },
  } = useMainSelector();

  const dispatch = useMainDispatch();
  const history = useHistory();

  const socketRef = useRef<Socket>();
  const userRef = useRef<TCurrentUser | undefined>(currentlyLoggedInUser);
  const currentUserRef = useRef<TChatUser | undefined>(currentUser);
  const currentUserMessagesRef = useRef<TChatHistory[]>(currentUserMessages);
  const chatListRef = useRef<TChatUser[]>(chatList);

  const newUser = useCallback(
    (user: TChatUser) => {
      console.debug(`Event received: ${ESocketEvents.NEW_USER} - calling newUser`);

      if (user?.id) {
        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            chatList: [...chatListRef.current, user],
          },
        });
      }
    },
    [dispatch],
  );

  const addMessage = useCallback(
    (messagePayload: TNewCustomerMessagePayload) => {
      console.debug(
        `Event received: ${ESocketEvents.NEW_CUSTOMER_MESSAGE} - calling addMessage with payload:`,
        messagePayload,
      );

      const { message, fromUser } = messagePayload;
      const { direction } = message;
      const currentUserId = currentUserRef.current?.id;

      if (direction === 'in') {
        if (fromUser === currentUserId) {
          console.debug('Updating message list');
          dispatch({
            type: Actions.SET_CHAT,
            payload: { currentUserMessages: [...currentUserMessagesRef.current, message] },
          });
        } else {
          console.debug('Received message for another user. Not updating message list', {
            fromUser,
            currentUser: currentUserId,
          });
        }
      }
    },
    [dispatch],
  );

  const closeChat = useCallback(
    (messagePayload: any) => {
      console.debug(`Event received: ${ESocketEvents.CUSTOMER_CLOSED_CHAT} - calling closeChat`);
      const chatBotUserId = messagePayload?.chatBotUserId;
      if (chatBotUserId) {
        const newChatList: TChatUser[] = chatListRef.current.filter((chatUser) => chatUser.id !== chatBotUserId);
        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            chatList: newChatList,
            currentUser: undefined,
            currentUserMessages: [],
          },
        });
      }
    },
    [dispatch],
  );

  const removeUserFromWaitingList = useCallback(
    (messagePayload: any) => {
      const chatBotUserId = messagePayload?.chatBotUserId;
      const agentEmail = messagePayload?.agentEmail;

      if (chatBotUserId && agentEmail && agentEmail === userRef.current?.email) {
        const newChatList: TChatUser[] = chatListRef.current.map((chatUser) =>
          chatUser.id === chatBotUserId ? { ...chatUser, assignedToAgent: true } : chatUser,
        );
        dispatch({
          type: Actions.SET_CHAT,
          payload: { chatList: newChatList, selectedChatList: 'assigned-to-me' },
        });
      } else {
        const newChatList: TChatUser[] = chatListRef.current.filter((chatUser) => chatUser.id !== chatBotUserId);
        dispatch({
          type: Actions.SET_CHAT,
          payload: { chatList: newChatList },
        });
      }
    },
    [dispatch],
  );

  const assignUserToInbox = useCallback(
    async (messagePayload: any) => {
      const { user } = messagePayload;
      const { id: chatBotUserId } = user;

      // * If agent is conversing with the user remove the user
      if (chatBotUserId === currentUserRef?.current?.id) {
        history.push(CHAT_PAGE_ROUTE);

        dispatch({
          type: Actions.SET_CHAT,
          payload: { currentUser: undefined, currentUserMessages: [] },
        });
      }

      try {
        const { data: labels } = await getConversationLabelsByIdApi(chatBotUserId);

        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            chatList: replaceValueInList<TChatUser>(
              { ...user, labels },
              chatListRef.current,
              (user) => user.id === chatBotUserId,
            ),
          },
        });
      } catch (error) {
        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            chatList: replaceValueInList<TChatUser>(user, chatListRef.current, (user) => user.id === chatBotUserId),
          },
        });
      }
    },
    [dispatch, history],
  );

  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser]);

  useEffect(() => {
    currentUserMessagesRef.current = currentUserMessages;
  }, [currentUserMessages]);

  useEffect(() => {
    chatListRef.current = chatList;
  }, [chatList]);

  useEffect(() => {
    userRef.current = currentlyLoggedInUser;
  }, [currentlyLoggedInUser]);

  useEffect(() => {
    if (currentlyLoggedInUser?.selectedCompanyId) {
      console.debug('Initializing websocket connection');
      socketRef.current = io(`${WS_ENDPOINT}/portal`, {
        ...socketIOSettings,
        query: {
          'conversed-bot': currentlyLoggedInUser.selectedCompanyId,
        },
      });

      console.debug(`Setting up listener for new customer message event - ${ESocketEvents.NEW_CUSTOMER_MESSAGE}`);
      socketRef.current.on(ESocketEvents.NEW_CUSTOMER_MESSAGE, addMessage);
      console.debug(`Setting up listener for customer closed event - ${ESocketEvents.CUSTOMER_CLOSED_CHAT}`);
      socketRef.current.on(ESocketEvents.CUSTOMER_CLOSED_CHAT, closeChat);
      console.debug(
        `Setting up listener for remove user from waiting list event - ${ESocketEvents.REMOVE_USER_FROM_WAITING_LIST}`,
      );
      socketRef.current.on(ESocketEvents.REMOVE_USER_FROM_WAITING_LIST, removeUserFromWaitingList);
      console.debug(`Setting up listener for new user to waiting list event - ${ESocketEvents.NEW_USER}`);
      socketRef.current.on(ESocketEvents.NEW_USER, newUser);

      console.debug(`Setting up listener for user assignment to queue - ${ESocketEvents.ASSIGN_USER_TO_QUEUE}`);
      socketRef.current.on(ESocketEvents.ASSIGN_USER_TO_QUEUE, assignUserToInbox);

      return () => {
        console.debug(`Removing listener for new customer message event - ${ESocketEvents.NEW_CUSTOMER_MESSAGE}`);
        socketRef?.current?.off(ESocketEvents.NEW_CUSTOMER_MESSAGE, addMessage);
        console.debug(`Removing listener for customer closed event - ${ESocketEvents.CUSTOMER_CLOSED_CHAT}`);
        socketRef?.current?.off(ESocketEvents.CUSTOMER_CLOSED_CHAT, closeChat);
        console.debug(
          `Removing listener for remove user from waiting list event - ${ESocketEvents.REMOVE_USER_FROM_WAITING_LIST}`,
        );
        socketRef?.current?.off(ESocketEvents.REMOVE_USER_FROM_WAITING_LIST, removeUserFromWaitingList);
        console.debug(`Removing listener for new user to waiting list event - ${ESocketEvents.NEW_USER}`);
        socketRef?.current?.off(ESocketEvents.NEW_USER, newUser);

        console.debug(`Removing listener for user assignment to queue - ${ESocketEvents.ASSIGN_USER_TO_QUEUE}`);
        socketRef?.current?.off(ESocketEvents.ASSIGN_USER_TO_QUEUE, assignUserToInbox);
        console.debug('Closing websocket connection');
        socketRef?.current?.disconnect();
      };
    }
  }, [
    addMessage,
    newUser,
    closeChat,
    removeUserFromWaitingList,
    assignUserToInbox,
    currentlyLoggedInUser?.selectedCompanyId,
  ]);

  return { socketRef };
};

export default useSocketEvents;
